import { devtools as _devtools } from "zustand/middleware";
import moment from "moment/moment";
import { STRAPI_URL } from "@/constants/api";
import { type ProjectDocumentRedisTicker } from "@/types";

export const devtools = (process.env.NODE_ENV === "production"
  ? (fn: never) => fn
  : _devtools) as unknown as typeof _devtools;

export const formatToArticleDate = (date: string) => moment(date).format("MMMM DD, YYYY");
export const formatToHighlightDate = (date: string) => moment(date).format("ll");

export const getStrapiMediaUrl = (route: string | undefined): string => {
  try {
    if (route === undefined) throw new Error("Route shouldn't be undefined");

    const url = new URL(route);

    if (url.protocol === "http:" || url.protocol === "https:") return `${route}`;
    else return `${STRAPI_URL}${route}`;
  } catch (_) {
    return `${STRAPI_URL}${route}`;
  }
};

export default function formatNumber(num: number, precision = 2) {
  const map = [
    { suffix: "T", threshold: 1e12 },
    { suffix: "B", threshold: 1e9 },
    { suffix: "M", threshold: 1e6 },
    { suffix: "K", threshold: 1e3 },
    { suffix: "", threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    return toFixedWithoutZeros(num / found.threshold, precision) + found.suffix;
  }

  return num;
}

export const toFixedWithoutZeros = (num: number, precision: number) => num.toFixed(precision).replace(/\.0+$/, "");

export const calculateDiscount = (discount?: number, price?: number) => {
  return discount !== undefined && price !== undefined ? price - Math.floor(discount * price) : price;
};

export const toPercentageValue = (docProgress: ProjectDocumentRedisTicker | undefined): number | undefined => {
  if (docProgress === undefined) return undefined;

  const documentDownload = docProgress.documentDownloaded ? 1 : 0;
  const healthCheck = docProgress.documentHealthcheckDone ? 1 : 0;
  const textraction = docProgress.textractionDone ? 1 : 0;
  const documentMetadataGatheringDone = docProgress.documentMetadataGatheringDone ? 1 : 0;
  const semanticEncoding = docProgress.textEmbeddingDone ? 1 : 0;
  const paragraphAggregationDone = docProgress.paragraphAggregationDone ? 1 : 0;
  const dbWriting = docProgress.dbWritingDone ? 1 : 0;

  const processingProgress =
    ((documentDownload +
      healthCheck +
      textraction +
      semanticEncoding +
      dbWriting +
      paragraphAggregationDone +
      documentMetadataGatheringDone) /
      7) *
    100;

  let summarizationProgress = (docProgress.numOfSummarizedParagraphs / docProgress.totalNumOfParagraphs) * 100;

  if (docProgress.totalNumOfParagraphs <= 0) {
    summarizationProgress = 0;
  } else {
    summarizationProgress = (docProgress.numOfSummarizedParagraphs / docProgress.totalNumOfParagraphs) * 100;
  }

  return (processingProgress + summarizationProgress) / 2;
};

export const isProcessingDone = (docProgress: ProjectDocumentRedisTicker | undefined | null): boolean => {
  if (!docProgress) return false;
  return docProgress.numOfSummarizedParagraphs === docProgress.totalNumOfParagraphs;
};
