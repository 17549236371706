import { cn } from "@/lib/utils";
import React, { type ElementRef, type SVGProps } from "react";

const CheckCircleOutlined = (): React.ReactNode => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.29 6.29L17.7 7.7L9 16.41L4.29 11.7L5.7 10.29L9 13.59L16.29 6.29ZM22 11C22 17.08 17.08 22 11 22C4.92 22 0 17.08 0 11C0 4.92 4.92 0 11 0C17.08 0 22 4.92 22 11ZM20.5 11C20.5 5.76 16.24 1.5 11 1.5C5.76 1.5 1.5 5.76 1.5 11C1.5 16.24 5.76 20.5 11 20.5C16.24 20.5 20.5 16.24 20.5 11Z"
        className="fill-primary"
      />
    </svg>
  );
};

const CancelIcon = (): React.ReactNode => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      width="40px"
      height="40px"
    >
      <path
        className="fill-current"
        d="M22.9503 19.8489L34.8169 31.7156L31.8669 34.6656L20.0003 22.7989L8.1336 34.6656L5.18359 31.7156L17.0503 19.8489L5.18359 7.98223L8.1336 5.03223L20.0003 16.8989L31.8669 5.03223L34.8169 7.98223L22.9503 19.8489Z"
        fillOpacity="0.75"
      ></path>
    </svg>
  );
};

const ArrowDownIcon = (props: SVGProps<ElementRef<"svg">>) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cn("h-6 w-6 fill-primary", props.className)}
  >
    <path
      className="fill-inherit"
      d="M10.5858 17.5858L4.41421 11.4142C3.15428 10.1543 4.04662 8 5.82843 8H18.1716C19.9534 8 20.8457 10.1543 19.5858 11.4142L13.4142 17.5858C12.6332 18.3668 11.3668 18.3668 10.5858 17.5858Z"
    />
  </svg>
);
export { CheckCircleOutlined, CancelIcon, ArrowDownIcon };
