import { GlobeIcon, LeftArrowIcon, SendIcon, TickIcon } from "@/components/icons/landing/demo";
import Link from "next/link";
import { CloseIcon } from "@/components/icons/landing/demo";
import neuro from "@/public/images/neuro.png";
import pageEnd from "@/public/images/pageEnd.png";
import space from "@/public/images/space.png";
import employeeorg from "@/public/images/employeorg.png";
import Image, { type StaticImageData } from "next/image";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { type Variants, motion, useScroll, useTransform, type MotionValue } from "framer-motion";
import { USE_CASES } from "@/constants/landing";

const HeaderButton = ({ title }: { title: string }) => {
  return (
    <button className="flex h-[36px] items-center justify-between gap-3 overflow-hidden truncate text-ellipsis whitespace-nowrap rounded-[7px] bg-white px-4 py-2 text-[14px]/[19.6px] font-bold text-dark-purple shadow-sm">
      {title}
      <CloseIcon />
    </button>
  );
};

const DemoAppHeader = ({ book }: { book: bookType }) => {
  return (
    <div className="hidden h-[48px] w-full items-center rounded-t-[32px] bg-primitive-purple-200 pr-8 text-[14px]/[17.6px] text-dark-purple md:flex">
      <Link className="flex h-4/5 items-center justify-between gap-[6px] pl-[32px] pr-[24px] font-semibold" href="/">
        <LeftArrowIcon />
        Library
      </Link>
      <HeaderButton title={book.title} />
    </div>
  );
};

const DocumentScroller = ({ book }: { book: bookType }) => {
  return (
    <div className="relative hidden h-full md:block md:w-2/5 lg:w-1/2 xl:w-3/5">
      <div className="absolute bottom-6 left-6 rounded-lg bg-black/40 px-4 py-2">
        <p className="text-[14px]/[19.6px] font-bold text-white">
          Page {book.pictureNum} of {book.totalPages}
        </p>
      </div>
      <div className="h-[670px] overflow-y-hidden rounded-bl-[32px] bg-primitive-grey-50">
        <Image className="mx-auto" src={pageEnd} alt="forensic_anthropology" />
        <Image className="mx-auto mt-10" src={book.picture} alt="forensic_anthropology" />
      </div>
    </div>
  );
};

const LanguageSelector = () => {
  return (
    <Select>
      <SelectTrigger className="h-[36px] w-auto">
        <div className="flex items-center justify-start gap-2 font-semibold">
          <GlobeIcon />
          <SelectValue className="text-[16px]/[20.16px]" placeholder="EN" />
        </div>
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="English">English</SelectItem>
        <SelectItem value="Turkish">Turkish</SelectItem>
      </SelectContent>
    </Select>
  );
};

const HoursSavedBox = ({ hours, minutes }: { hours: string; minutes: string }) => {
  return (
    <div className="flex items-center gap-4 rounded-lg border border-primitive-green-300 bg-primitive-green-50 p-2 sm:p-4">
      <TickIcon className="min-h-10 min-w-10" />
      <p className="text-[14px]/[19.8px] text-primitive-green-900 sm:text-[16px]/[22.4px]">
        Wow! You&apos;ve just saved{" "}
        <span className="font-bold">
          {hours} hours {minutes} minutes
        </span>{" "}
        by summarizing this document.
      </p>
    </div>
  );
};

type pages = {
  pageDist: string;
  paragraphs: { content: string }[];
};
type chapters = {
  chapterName: string;
  pages: pages[];
};

type bookType = {
  picture: StaticImageData;
  pictureNum: string;
  title: string;
  author: string;
  year: string;
  totalPages: string;
  categories: string[];
  chapters: chapters[];
};

const neuroscienceBook: bookType = {
  picture: neuro,
  pictureNum: "242",
  title: "Foundations of Neuroscience",
  author: "Casey Henley",
  year: "2021",
  totalPages: "464",
  categories: ["Biology", "SHARED"],
  chapters: [
    {
      chapterName: "The Neuron",
      pages: [
        {
          pageDist: "Page 13-27",
          paragraphs: [
            {
              content:
                "Neurons have dendrites that receive signals from other cells, a cell body (soma) containing organelles responsible for cellular functions, an axon transmitting electrical signals called action potentials to communicate with other cells, and myelin sheaths that increase signal speed. The length of an axon varies depending on its function, with sensory neurons having long axons and spinal interneurons having short ones. Axon diameter affects signal propagation speed, with larger diameters allowing faster transmission. Axons terminate at presynaptic terminals, where neurotransmitters are released to communicate with other cells through synapses.",
            },
            {
              content:
                "Neurons consist of a cell body, dendrites, and an axon, with variations in structure depending on their location and function. Neurons can be unipolar, bipolar, or multipolar, each with different branching characteristics. Despite these variations, all neurons share the common features of the cell body, dendrites, and axon. The neuron's structure can vary based on its location and function. Ion movement is crucial for neuron function, with the phospholipid bilayer forming a barrier that prevents ion movement into and out of the neuron.",
            },
          ],
        },
      ],
    },
    {
      chapterName: "Ion Movement",
      pages: [
        {
          pageDist: "Page 27-32",
          paragraphs: [
            {
              content:
                "Ion movement in neurons is facilitated by ion channels embedded in the cell membrane. These channels open in various ways, such as spontaneously, in response to voltage changes, or upon chemical binding. Different channels can be specific to certain ions or allow the passage of multiple ions. Ion movement is driven by concentration and electrical gradients, with ions moving from areas of high concentration to low concentration. When these gradients balance, ions reach equilibrium, with no net movement across the membrane. The phospholipid bilayer restricts ion movement, while ion channels enable it. At equilibrium, ions continue to move through open channels without a net flow.",
            },
          ],
        },
      ],
    },
  ],
};

const spaceBook: bookType = {
  picture: space,
  pictureNum: "23",
  title: "The GENERAL THEORY of RELATIVITY",
  author: "Anonymus",
  year: "2024",
  totalPages: "25",
  categories: ["Physics", "SHARED"],
  chapters: [
    {
      chapterName: "Gravity's Nature",
      pages: [
        {
          pageDist: "Page 6-8",
          paragraphs: [
            {
              content:
                "In General Relativity, Einstein linked gravity to the curvature of spacetime, realizing that gravitational fields are distortions of the 4-dimensional spacetime geometry caused by mass-energy. He formulated the general field equation, showing that the distortion of spacetime is proportional to the mass-energy at a given point. Just like electromagnetic fields have electric and magnetic distortions, gravitational fields have distortions caused by mass-energy sources. These distortions interact with test objects, causing forces that make them move. Interactions between different sources are generated through the field, acting as a medium for mediation. Additionally, fields can carry waves.",
            },
            {
              content:
                "Fields can carry waves due to changes in field distortions with time, leading to energy propagation at a certain velocity. Oscillating electric charges create electromagnetic waves that can be detected by other charges, while oscillating mass-energies generate gravitational waves detectable by other mass-energies. The distortion of spacetime caused by mass-energy carries energy itself, which can further distort spacetime, allowing gravitational fields to self-amplify. Einstein's General Theory of Relativity (GR) was initially met with little professional attention due to gravity being a weak force. However, key predictions from GR were swiftly experimentally confirmed, leading to Einstein's widespread fame. GR became essential in astrophysics in the 21st century, particularly for understanding phenomena like black holes and the evolution of galaxies and the universe. Experimental tests of GR have been conducted since 1915, including weak field tests like the precession of Mercury's orbit, highlighting the theory's accuracy and importance in explaining various astrophysical phenomena.",
            },
          ],
        },
      ],
    },
  ],
};

const employeeBook: bookType = {
  picture: employeeorg,
  pictureNum: "2",
  title: "The State of Energy Organizations",
  author: "McKinsey",
  year: "2024",
  totalPages: "77",
  categories: ["Finance", "SHARED"],
  chapters: [
    {
      chapterName: "Energy organizations in transition",
      pages: [
        {
          pageDist: "Page 6-8",
          paragraphs: [
            {
              content:
                "Energy organizations in the transition phase are faced with critical decisions to ensure future success. The choice between a business unit-centric or function-centric model is crucial for maximizing value creation and growth. Companies in the energy sector must balance their traditional core business with new ventures in renewables, low-carbon solutions, and retail to meet the increasing demand for affordable and reliable energy. Operational excellence, leadership development, talent management, and successful mergers and acquisitions are key focus areas for energy organizations. The debate between BU-centric and asset-centric models continues, with a need for bold actions and innovative solutions. Companies are reevaluating their operating models and focusing on fundamental changes to drive productivity and performance. Talent retention and acquisition, as well as navigating the integration of new energy businesses with the existing core, pose significant challenges for energy companies. Maintaining a skilled workforce and adapting to technological advancements are essential for success in the evolving energy landscape.",
            },
            {
              content:
                "Many firms are reevaluating their Employee Value Proposition (EVP) to attract talent, focusing on creating career opportunities, reshaping corporate culture, and changing perceptions of senior leaders. Operators are using cash flows to manage capital, balance sheets, and return cash to shareholders. Leaders need to adapt to new challenges and opportunities, driven by technologies like gen AI. M&A activity is increasing globally, especially in the oil and gas sector. Successful M&As focus on creating value and integrating organizations. Gen AI can revolutionize the energy industry by enabling efficiency, better decision making, and resource management. Energy organizations need to strategically plan their operating models, leadership, talent, and M&A to stay ahead in a low-carbon world. The energy transition toward cleaner energy offers a significant market opportunity, with electric power and hydrogen expected to grow. Energy incumbents are investing in new energy businesses to capitalize on this opportunity.",
            },
          ],
        },
      ],
    },
    {
      chapterName: "New energy businesses: The independence versus integration dilemma",
      pages: [
        {
          pageDist: "Page 8-12",
          paragraphs: [
            {
              content:
                "The success of new energy ventures in Fortune 100 companies usually occurs after around four years, with most being only partially successful. Established companies are now utilizing disruption strategies to innovate, prompting them to find a balance between dependence on existing systems and independence for agility. Different operating models, like full separation or integration within the parent company, come with their own benefits and risks. Creating fully independent ventures can lead to rapid expansion and autonomy in decision-making, but may sacrifice access to the parent company's resources. On the other hand, integration into the existing structure allows for gradual independence as the business matures, driven by factors like technology type and synergies with the core business. These decisions are crucial for maximizing operational performance and employee engagement, as well as avoiding common pitfalls in business building.",
            },
            {
              content:
                "In 2006, EDP established a separate division dedicated to renewable energy, which later became EDPR through significant growth. EDPR went public in 2008, raising capital for renewable energy projects and showing a commitment to expansion. Incumbents in the energy sector need to strategically integrate new energy businesses with the core business, considering factors like steering model, control level, strategy setting, capital allocation, talent approach, and operational processes. Efficient project management and scalability are crucial for success. Decision-making processes should be streamlined to respond quickly to market opportunities. Leaders setting up integrated new businesses should prioritize talent acquisition, avoid short-term earnings pressure, provide rapid access to capital, allow for customization of core processes, and foster an entrepreneurial culture aligned with the parent company's values. Building partnerships within the energy ecosystem can accelerate growth, and ensuring financial flexibility is essential for seizing opportunities. Aligning the new business's culture with the parent company's values promotes collaboration and purpose. Incumbents should carefully consider these factors to drive rapid growth and success in a competitive industry.",
            },
          ],
        },
      ],
    },
  ],
};

const Summary = ({ book }: { book: bookType }) => {
  const { scrollYProgress } = useScroll();

  function useParallax(value: MotionValue<number>, distance: number) {
    return useTransform(value, [-0.6, 1.1], [distance, -distance]);
  }
  const y = useParallax(scrollYProgress, 800);

  const paragraphVariants: Variants = {
    offscreen: {
      x: 50,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 1,
      },
    },
  };

  return (
    <motion.div
      className="mt-2 h-full"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <motion.div
        style={{ y }}
        className="h-[386px] overflow-y-hidden md:h-[516px] md:overflow-y-hidden  min-[970px]:overflow-y-hidden lg:h-[516px]"
        variants={paragraphVariants}
      >
        {book.chapters.map((summary) => (
          <div key={summary.chapterName} className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <h4 className="text-[18px]/[25.2px] font-bold text-primitive-purple-800">{summary.chapterName}</h4>
              <p className="text-[14px]/[19.6px] text-black/70">{summary.pages[0].pageDist}</p>
            </div>
            {summary.pages.map((page) => (
              <div key={page.pageDist} className="flex flex-col items-center gap-2">
                <div>
                  {page.paragraphs.map((paragraph, index) => (
                    <p key={index} className="mb-4 text-[16px]/[24.8px] text-dark-purple">
                      {paragraph.content}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

const ChatInputField = () => {
  return (
    <div className="absolute bottom-0 right-0 flex h-[96px] w-full items-center justify-center rounded-b-[32px] bg-white px-[25px] pb-6 pt-4 md:w-3/5 lg:w-1/2 xl:w-2/5">
      <Input
        className="h-[56px] rounded-2xl border-2 border-primitive-purple-700 px-4 py-3 shadow-sm"
        placeholder="Ask anything..."
      />
      <Link href="/" className="absolute right-[38px] top-[30px]">
        <SendIcon />
      </Link>
    </div>
  );
};

const SummaryScroller = ({ book }: { book: bookType }) => {
  return (
    <div className="h-full w-full px-[25px] py-[30px] md:w-3/5  lg:w-1/2 xl:w-2/5">
      <div className="flex flex-col items-start justify-between gap-8">
        <div className="absolute right-0 top-[181px] z-50 flex w-full flex-col gap-8 bg-white p-6 md:top-12 md:w-3/5 lg:w-1/2 xl:w-2/5 ">
          <div className="flex w-full flex-col items-start justify-between gap-[22px] sm:flex-row sm:items-center">
            <h2 className="text-[24px]/[30.24px] font-semibold text-primitive-purple-950">Document Summary</h2>
            <LanguageSelector />
          </div>
          <HoursSavedBox hours={"12"} minutes={"32"} />
        </div>
        <Summary book={book} />
        <ChatInputField />
      </div>
    </div>
  );
};

const MobileHeader = ({ book }: { book: bookType }) => {
  return (
    <div className="flex w-full flex-row-reverse items-center justify-start gap-8 rounded-t-[32px] bg-primitive-grey-50 px-4 py-6 min-[390px]:flex-row md:hidden">
      <Image
        className="hidden max-h-[89px] max-w-[71px] min-[340px]:block min-[390px]:max-h-[135px] min-[390px]:max-w-[107px]"
        src={book.picture}
        alt={book.title}
      />
      <div className="flex flex-col items-start">
        <h3 className="text-[16px]/[22.4px] font-bold text-primitive-purple-950 min-[390px]:text-[20px]/[28px]">
          {book.title}
        </h3>
        <p className=" mb-3 text-[14px]/[19.6px] font-normal text-black/70 min-[390px]:text-[16px]/[22.4px]">
          {book.author},{book.year} • {book.totalPages}
        </p>
        <div className="flex items-center justify-between gap-2">
          <div className="rounded-4xl bg-primitive-green-500 px-3 py-2 text-[12px]/[15.12px] font-semibold capitalize text-white">
            {book.categories[0]}
          </div>
          <div className="rounded-4xl bg-primitive-grey-400 px-3 py-2 text-[12px]/[15.12px] font-semibold capitalize text-white">
            {book.categories[1]}
          </div>
        </div>
      </div>
    </div>
  );
};

const DemoApp = ({ selectedTab }: { selectedTab: string }) => {
  let selectedBook: bookType;

  switch (selectedTab) {
    case USE_CASES.STUDENTS: {
      selectedBook = neuroscienceBook;
      break;
    }
    case USE_CASES.PROFESSIONALS: {
      selectedBook = employeeBook;
      break;
    }
    case USE_CASES.RESEARCHERS: {
      selectedBook = spaceBook;
      break;
    }
    default: {
      selectedBook = neuroscienceBook;
      break;
    }
  }

  return (
    <div className="pointer-events-none relative m-auto h-[720px] max-w-[1280px] rounded-[32px] border bg-white shadow-2xl">
      <div className="">
        <DemoAppHeader book={selectedBook} />
        <div className="flex h-full w-full flex-col md:flex-row ">
          <MobileHeader book={selectedBook} />
          <DocumentScroller book={selectedBook} />
          <SummaryScroller book={selectedBook} />
        </div>
      </div>
    </div>
  );
};

export default DemoApp;
