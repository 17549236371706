"use client";

import React, { type Dispatch, type SetStateAction, useState } from "react";
import { PlusJakartaSans } from "@/service/fonts";
import { cn } from "@/lib/utils";
import { BackpackIcon, PieChartIcon, ChecklistIcon } from "@/components/icons/landing/demo";
import DemoApp from "@/components/landing/DemoApp";
import { USE_CASES } from "@/constants/landing";
import { mixpanelTrack } from "@/service/utils";

const HeaderButton = ({
  text,
  icon,
  selectedTab,
  setSelectedTab,
}: {
  text: string;
  icon: React.JSX.Element;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <button
      onClick={() => {
        setSelectedTab(text.toLocaleLowerCase());
        mixpanelTrack("demo_app_navigation_clicked");
      }}
      className={cn(
        "focused group flex h-[50px] items-center justify-between gap-2 rounded-[100px] border border-primitive-grey-700 bg-transparent px-6 py-4 text-primitive-grey-700 hover:border-primitive-purple-500",
        selectedTab === text.toLocaleLowerCase() ? "text-primary ring ring-primitive-purple-400" : "",
      )}
    >
      {icon}
      <p className="font-semibold group-hover:text-primitive-purple-950">{text}</p>
    </button>
  );
};

const DemoHeader = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="no-scrollbar m-auto mb-6 flex h-[4.375rem] max-w-[73.75rem] items-center overflow-x-auto pl-4 ">
      <div className="container flex gap-2 ">
        <HeaderButton
          text="Students"
          icon={<BackpackIcon selectedTab={selectedTab} />}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <HeaderButton
          text="Professionals"
          icon={<PieChartIcon selectedTab={selectedTab} />}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <HeaderButton
          text="Researchers"
          icon={<ChecklistIcon selectedTab={selectedTab} />}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
    </div>
  );
};

const LandingDemo = () => {
  const [selectedTab, setSelectedTab] = useState(USE_CASES.STUDENTS);
  return (
    <section className={cn("mb-20 w-full text-primary ", PlusJakartaSans.className)}>
      <div className="relative -z-50 w-[100%] translate-y-80 -skew-y-[4.026deg]">
        <div className="absolute -z-50 h-[1000px] w-[100%] translate-y-10 bg-[#271443]"></div>
      </div>
      <div className="px-4 sm:px-6">
        <DemoHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <DemoApp selectedTab={selectedTab} />
      </div>
    </section>
  );
};

export default LandingDemo;
