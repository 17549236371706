"use client";
import React, { type ElementRef, type SVGProps, useEffect, useRef, useState } from "react";
import { PlusJakartaSans } from "@/service/fonts";
import { cn } from "@/lib/utils";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  ThreeBooks,
  DropdownArrow,
  UploadDesignBehind,
  UploadDesignFront,
  UploadDesignMobile,
  UploadDesignTablet,
  HeroGradient,
  HeroErrorGradient,
  ExclamationErrorMarks,
  LoadingGroup,
  DroppedBooks,
} from "@/components/icons/landing/hero";
import { PAGE_IDS, QUERY_PARAMS, QUINO_APP_URL } from "@/constants/landing";
import { GoogleDriveIcon, OneDriveIcon } from "@/components/icons/landing/hero";
import { useForcedMixpanelRedirectTrack } from "@/service/hooks";
import { ReactTyped } from "react-typed";
import "./Hero.css";
import { getMixpanelDistinctId, getRequest, mixpanelTrack, postRequestForFile } from "@/service/utils";
import { type ProjectDocumentRedisTicker, type TrialFileUpload } from "@/types";
import { useInterval } from "usehooks-ts";
import { isProcessingDone, toPercentageValue } from "@/utils";
import { SpinnerWithValue } from "@/components/loading";
import { useDropzone } from "react-dropzone";
import { UPLOAD_FILE } from "@/constants/core";

const UploadDropdown = ({
  fill,
  className,
  props,
  redirect,
}: {
  fill?: string;
  className?: string;
  props?: SVGProps<ElementRef<"svg">>;
  redirect: (link: string, event: string, payload?: undefined) => void;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className={cn("rounded-r-lg border-l", className)}>
          <DropdownArrow fill={fill} {...props} />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="flex cursor-pointer items-center justify-start gap-2 hover:bg-primitive-purple-50"
            onClick={() => redirect(`${QUINO_APP_URL}/register`, "file_upload_from_landing")}
          >
            <span>Google Drive</span>
            <GoogleDriveIcon />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex cursor-pointer items-center justify-start gap-2 hover:bg-primitive-purple-50"
            onClick={() => redirect(`${QUINO_APP_URL}/register`, "file_upload_from_landing")}
          >
            <span>One Drive</span>
            <OneDriveIcon />
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const TypeWriter = () => {
  const docTypes = ["presentations", "PDFs", "textbooks"];
  return (
    <section className="min-h-[49px] text-[44px]/[48.4px] font-semibold tracking-[-1.6px] text-accent-pink md:min-h-[88px] md:text-[80px]/[88px]">
      <ReactTyped loop backSpeed={25} strings={docTypes} typeSpeed={25} />
    </section>
  );
};
const TypeWriterDots = () => {
  const docTypes = [".", ".."];
  return (
    <span>
      {"."}
      <ReactTyped loop backSpeed={25} startDelay={500} strings={docTypes} typeSpeed={25} showCursor={false} />
    </span>
  );
};
const TOTAL_PROGRESS = 100;
const MOCK_UPLOAD_LIMIT = 15;
const REST_OF_PROGRESS = TOTAL_PROGRESS - MOCK_UPLOAD_LIMIT;
const POLL_ERROR_LIMIT = 10;
const Hero = () => {
  const redirect = useForcedMixpanelRedirectTrack();
  const rootRefElem = useRef<HTMLDivElement>(null);
  const { getRootProps, getInputProps, rootRef, isDragActive, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/x-latex": [".latex"],
      "text/x-tex": [".tex", ".ltx", ".sty", ".cls"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
      "text/plain": [".txt"],
      "text/html": [".html", ".htm"],
    },
  });

  const [state, setState] = useState<{
    isFileUploadLoading: boolean;
    isPollingActive: boolean;
    isFileUploadDone: boolean;
    isFileUploadSuccess: boolean;
    fileUploadState: "SUCCESSS" | "ERROR" | "NOT_START" | "LOADING";
    fileUploadResponse: TrialFileUpload | null;
    fileProcessingState: number;
    pollErrorCount: number;
    uploadingStateText: string;
    distinctIdCache: string;
  }>({
    isFileUploadLoading: false,
    isPollingActive: false,
    isFileUploadDone: false,
    isFileUploadSuccess: false,
    fileUploadState: "NOT_START",
    fileUploadResponse: null,
    fileProcessingState: 0,
    pollErrorCount: 0,
    uploadingStateText: "Uploading",
    distinctIdCache: "",
  });

  useInterval(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async () => {
      if (state.fileUploadResponse?.temp_document_id) {
        try {
          const res: ProjectDocumentRedisTicker = await getRequest<ProjectDocumentRedisTicker>(
            `/api/trial/fds-progress?id=${state.fileUploadResponse.temp_document_id}`,
            { next: { revalidate: 0 }, cache: "no-cache" },
          );
          const processingPercentage =
            ((toPercentageValue(res) ?? 0) / TOTAL_PROGRESS) * REST_OF_PROGRESS + MOCK_UPLOAD_LIMIT;

          setState((prev) => ({
            ...prev,
            pollErrorCount: 0,
            fileProcessingState: processingPercentage,
            uploadingStateText: processingPercentage >= 50 ? "Summarizing" : "Processing",
          }));

          if (isProcessingDone(res)) {
            setState((prev) => ({ ...prev, pollErrorCount: 0, fileProcessingState: 100 }));
            const mixpanelId = state.distinctIdCache;
            window.location.replace(
              `${QUINO_APP_URL}/temporary/documents/all/preview?document=${res.documentId}&note-tab=document-summary${mixpanelId ? `&${QUERY_PARAMS.MIXPANEL_DISTINCT_ID}=${mixpanelId}` : ""}`,
            );
          }
          if (res.errorMessageDuringDocumentProcessing) {
            setState((prev) => ({ ...prev, pollErrorCount: 0, fileUploadState: "ERROR", fileProcessingState: 0 }));
          }
        } catch (e) {
          if (state.pollErrorCount < POLL_ERROR_LIMIT)
            setState((prev) => ({ ...prev, pollErrorCount: prev.pollErrorCount + 1 }));
          else setState((prev) => ({ ...prev, fileProcessingState: 0, fileUploadState: "ERROR" }));
        }
      }
    },
    state.isPollingActive && state.fileUploadState === "LOADING" ? 2000 : null,
  );

  const uploadFile = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append(UPLOAD_FILE, file);
      setState((prev) => ({
        ...prev,
        isFileUploadLoading: true,
        isPollingActive: false,
        fileUploadState: "LOADING",
        fileUploadResponse: null,
        pollErrorCount: 0,
        uploadingStateText: "Uploading",
        fileProcessingState: 0,
        distinctIdCache: getMixpanelDistinctId(),
      }));
      const mockUpload = setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          fileProcessingState: Math.min(prevState.fileProcessingState + 0.5, MOCK_UPLOAD_LIMIT),
        }));
      }, 100);
      const response = await postRequestForFile<TrialFileUpload>("/api/trial/upload-document", formData);
      mixpanelTrack("prereg_fds_document_upload", {
        success: true,
        document_type: file.type,
        document_size_in_mb: file.size / (1024 * 1024),
      });
      clearTimeout(mockUpload);

      setState((prev) => ({
        ...prev,
        isFileUploadLoading: false,
        isPollingActive: true,
        fileUploadResponse: response,
        uploadingStateText: "Processing",
        fileProcessingState: MOCK_UPLOAD_LIMIT,
      }));
    } catch {
      setState((prev) => ({
        ...prev,
        isFileUploadLoading: false,
        fileUploadState: "ERROR",
        isPollingActive: false,
        fileUploadResponse: null,
      }));
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      void uploadFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (isDragActive) {
      console.log(rootRefElem.current?.getBoundingClientRect());
    }
  }, [isDragActive]);

  return (
    <section
      id={PAGE_IDS.HERO}
      className={cn("relative my-18 w-full overflow-x-clip text-dark-purple", PlusJakartaSans.className)}
    >
      <div className="hero-background absolute top-[-50%] -z-50 h-[300%] w-full bg-cover bg-no-repeat" />
      <div className="m-auto mx-auto max-w-[1180px] px-4 sm:px-6">
        <div className="container mx-auto flex flex-col-reverse items-center gap-[72px] md:flex-row xl:gap-[133px]">
          <div className="md:hidden ">
            <UploadDesignMobile className="-mt-8" />
          </div>
          <div className="-mt-14 flex max-w-[484px] flex-col items-start gap-8 md:-mt-11 lg:max-w-full">
            <h1 className="text-[44px]/[48.4px] font-light tracking-[-1.6px] md:text-[80px]/[88px]">
              <span className="whitespace-nowrap">Summarize your </span>
              <br />
              <TypeWriter />
              in seconds
            </h1>
            <p className="text-[24px]/[33.6px]">
              Accelerate your learning! Get instant insights and ask questions from your documents with AI.
            </p>
            <div className="flex h-[60px] w-[243px] rounded-[16px] bg-primitive-purple-700 shadow-xl hover:bg-primitive-purple-700/80 lg:hidden">
              <button
                className="flex cursor-pointer items-center justify-center text-nowrap rounded-l-lg px-8 py-6 text-[16px] font-semibold text-white"
                onClick={open}
              >
                Upload a file...
              </button>
              <input
                {...getInputProps({
                  multiple: false,
                })}
              />
              <UploadDropdown fill="#FFFFFF" className="p-6" redirect={redirect} />
            </div>
          </div>
          <div className="absolute right-0 hidden md:block lg:hidden">
            <UploadDesignTablet />
          </div>
          <div className="relative mr-8 hidden lg:block" {...getRootProps()}>
            <UploadDesignBehind
              className="absolute -left-40 -top-10 -z-40 hidden data-[active=true]:block"
              data-active={state.fileUploadState === "NOT_START"}
            />
            <UploadDesignFront
              className="absolute -top-10 z-40 hidden data-[active=true]:block"
              data-active={state.fileUploadState === "NOT_START"}
            />
            <HeroGradient
              className="absolute z-40 hidden max-w-full rounded-[32px] data-[active=true]:block"
              data-active={state.fileUploadState === "NOT_START" || state.fileUploadState === "LOADING"}
            />
            <HeroErrorGradient
              className="absolute z-40 hidden max-w-full rounded-[32px] data-[active=true]:block"
              data-active={state.fileUploadState === "ERROR"}
            />
            <div
              id="one-element"
              className={cn(
                "dropzone-bg  flex h-[381.989px] w-[351px] flex-col items-center gap-8 rounded-[32px] border border-dashed border-primitive-purple-400 bg-cover p-10 shadow-2xl xl:w-[391px]",
                state.fileUploadState === "ERROR" && "border-primitive-red-500",
              )}
              ref={rootRefElem}
            >
              <div
                id="drag-overlay"
                className="fixed left-0 top-0 z-[9999999] hidden h-screen w-screen bg-black/50 opacity-0 transition-all data-[show=true]:block data-[show=true]:opacity-100"
                data-show={isDragActive}
              >
                <div
                  className="overlay-dropzone absolute left-0 top-0 z-[500] flex h-[381.989px] w-[351px] flex-col items-center justify-center gap-8 rounded-[32px] border-2 border-solid border-primitive-purple-600 bg-white p-10 xl:w-[391px]"
                  style={{
                    left: rootRefElem.current?.getBoundingClientRect().left,
                    top: rootRef.current?.getBoundingClientRect().top,
                  }}
                >
                  <DroppedBooks />
                  <p className="text-center font-jakarta text-lg font-bold leading-[140%] text-primitive-purple-900">
                    Drop the files here to start uploading
                  </p>
                </div>
              </div>

              {state.fileUploadState === "LOADING" && <SpinnerWithValue value={state.fileProcessingState} />}
              <ThreeBooks
                className="hidden data-[active=true]:block"
                data-active={state.fileUploadState === "NOT_START"}
              />
              <ExclamationErrorMarks
                className="hidden data-[active=true]:block"
                data-active={state.fileUploadState === "ERROR"}
              />
              <LoadingGroup
                className="absolute bottom-0 hidden data-[active=true]:block"
                data-active={state.fileUploadState === "LOADING"}
              />
              <div>
                <h4
                  className="hidden text-[18px]/[25.2px] font-semibold data-[active=true]:block"
                  data-active={state.fileUploadState === "NOT_START"}
                >
                  Drag and drop or choose your file
                </h4>
                <h4
                  className="relative hidden text-[18px]/[25.2px] font-semibold data-[active=true]:block"
                  data-active={state.fileUploadState === "LOADING"}
                >
                  {state.uploadingStateText}
                  <span className="absolute left-full">
                    <TypeWriterDots />
                  </span>
                </h4>
              </div>
              <h4
                className="hidden text-[18px]/[25.2px] font-semibold data-[active=true]:block"
                data-active={state.fileUploadState === "ERROR"}
              >
                Couldn&apos;t upload your file
              </h4>
              <p
                className="hidden text-center text-[16px]/[22.4px] text-purple-pale-700 data-[active=true]:block"
                data-active={state.fileUploadState === "NOT_START"}
              >
                Upload a .pdf, .docx, .doc, or .txt file to get your summary.
              </p>
              <p
                className="hidden text-center text-[16px]/[22.4px] text-purple-pale-700 data-[active=true]:block"
                data-active={state.fileUploadState === "ERROR"}
              >
                Ooops... something went wrong
              </p>
              <div
                className="z-50  hidden h-[44px] rounded-lg bg-white shadow-xl data-[active=true]:flex"
                data-active={state.fileUploadState === "NOT_START"}
              >
                <button
                  type="button"
                  className="flex cursor-pointer items-center justify-center rounded-l-lg p-4 text-[16px] font-semibold hover:bg-primitive-purple-50"
                  onClick={open}
                >
                  Upload a file...
                </button>
                <input
                  {...getInputProps({
                    multiple: false,
                  })}
                />
                <UploadDropdown className="p-3 hover:bg-primitive-purple-50" redirect={redirect} />
              </div>
              <div
                className="z-50  hidden h-[44px] rounded-lg bg-white shadow-xl data-[active=true]:flex"
                data-active={state.fileUploadState === "ERROR"}
              >
                <button
                  className="flex cursor-pointer items-center justify-center rounded-l-lg p-4 text-[16px] font-semibold"
                  type="button"
                  onClick={open}
                >
                  Try again
                </button>
                <input
                  {...getInputProps({
                    multiple: false,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
