import Lottie from "lottie-react";
import ReactLottie from "react-lottie";
import { cn } from "@/lib/utils";

import animationData from "@/assets/lottie/loader/loader.json";
import infiniteLoad from "@/assets/lottie/loader/load-infinite.json";
import { type ElementRef, type HTMLProps } from "react";
import { Loader2 } from "lucide-react";

export const LoadingSpinner = (props: HTMLProps<ElementRef<"section">>) => (
  <span {...props} className={cn("flex h-full w-full flex-col items-center justify-center", props.className)}>
    <Loader2 className="h-20 w-20 animate-spin stroke-primary" />
  </span>
);

export const LottieLoadInfinite = ({}: { className?: string }) => {
  return (
    <ReactLottie
      height={160}
      width={160}
      speed={2}
      options={{
        animationData: infiniteLoad,
        loop: true,
        autoplay: true,
      }}
    />
  );
};

export const SpinnerWithValue = ({ value }: { value: number }) => (
  <section className="relative flex flex-row items-center gap-2">
    <LottieLoadInfinite className="h-40 w-40 animate-spin stroke-primitive-purple-600" />
    <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl tracking-tight">
      {value.toFixed(0)}%
    </span>
  </section>
);
export const LoadingPanel = ({ className }: { className?: string }) => (
  <section className={cn("flex flex-row items-center justify-center", className)}>
    <Lottie
      className="rounded-3xl bg-transparent [&_g]:!fill-primary [&_g]:!stroke-primary [&_path]:!fill-primary [&_path]:!stroke-primary [&_svg]:!fill-primary [&_svg]:!stroke-primary"
      loop
      animationData={animationData}
    />
  </section>
);
const Loading = () => (
  <main className="mx-auto flex min-h-screen w-full  flex-col items-center justify-center bg-primary">
    <section className="flex max-w-sm flex-row items-center justify-center">
      <Lottie loop animationData={animationData} />
    </section>
  </main>
);

export default Loading;
