import { useMemo } from "react";

import { CurlyArrow, EnvelopeSwoshIcon, Slash, UpgradeToProIcon } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { type CleanStripePlansResponse, type PlanDescription } from "@/types";

import Link from "next/link.js";
import { cn } from "@/lib/utils";
import FeatureList from "@/components/plans/subscriptions/feature-list";
import { LANDING_REDIRECT_ROUTES } from "@/constants/landing";

const PlanCard = ({
  plan,
  period,
  className,
  showArrow = false,
  highlighted = false,
  buttonPosition = "top",
  stripePlans,
}: {
  plan: PlanDescription;
  isLoading?: boolean;
  period: string;
  className?: string;
  buttonPosition?: "top" | "bottom";
  showArrow?: boolean;
  highlighted?: boolean;
  stripePlans: CleanStripePlansResponse | undefined;
}) => {
  const firebaseRoleName = plan.firebaseRole;

  const currentCurrencySymbol = plan.currency === "eur" ? "€" : "$";

  const actionButton = useMemo(() => {
    if (firebaseRoleName === "free")
      return (
        <Link className="w-full" href={LANDING_REDIRECT_ROUTES.LOGIN}>
          <Button className="flex w-full gap-2" variant="secondary">
            Sign up for free
          </Button>
        </Link>
      );
    if (firebaseRoleName.startsWith("pro"))
      return (
        <Link className="w-full" href={LANDING_REDIRECT_ROUTES.LOGIN}>
          <Button className={cn("flex w-full gap-2")}>
            Subscribe to Pro <UpgradeToProIcon />
          </Button>
        </Link>
      );

    return (
      <Link className="w-full" href="mailto:hello@quino.ai">
        <Button className="flex w-full gap-2" variant="secondary">
          Contact us <EnvelopeSwoshIcon />
        </Button>
      </Link>
    );
  }, [firebaseRoleName]);

  return (
    <div
      className={cn(
        "flex h-full flex-col gap-4 md:gap-8 rounded-2xl px-4 py-6 md:px-6 md:py-8 data-[highlighted=true]:relative data-[priceid=enterprise]:hidden data-[highlighted=true]:border data-[highlighted=true]:border-stroke-primary-onBg data-[highlighted=true]:bg-pro-plan-radial-gradient data-[highlighted=true]:shadow-lg data-[priceid=enterprise]:md:flex",
        className,
      )}
      data-highlighted={highlighted}
      data-priceid={plan.priceId}
    >
      <div
        className="absolute left-1/2 top-[-14px] hidden translate-x-[-50%] rounded-full bg-default px-3 py-2 text-label-l4 font-bold text-white data-[highlighted=true]:block"
        data-highlighted={highlighted}
      >
        MOST POPULAR
      </div>
      <div
        className="absolute right-[-0.73px] top-[-18px] hidden data-[show-arrow=true]:block"
        data-show-arrow={showArrow}
      >
        <CurlyArrow />
      </div>
      <div className="flex flex-col items-center gap-4 md:gap-6">
        <div className="flex-col items-center gap-1">
          <h4 className="text-primary-onBg truncate text-center text-h4">{plan.title}</h4>
          <p className="truncate text-center text-base font-normal text-secondary-onBg">{plan.subTitle}</p>
        </div>
        {plan.priceId === "enterprise" ? (
          <h2 className="text-primary-onBg relative text-center text-h1">Custom</h2>
        ) : (
          <h2 className="text-primary-onBg relative text-center text-h1">
            {currentCurrencySymbol}
            {plan.price}
            <span
              className="absolute -right-10 -top-2 text-xl text-decorative-red data-[hidden=true]:hidden"
              data-hidden={period === "monthly" || plan.monthlyPrice === 0}
            >
              {currentCurrencySymbol}
              {plan.monthlyPrice}
              <Slash className="absolute -right-1 top-1" />
            </span>
          </h2>
        )}

        <p className="text-xs font-normal text-secondary-onBg">{plan.description}</p>
        {buttonPosition === "top" && actionButton}
      </div>
      <FeatureList stripePlans={stripePlans} currentPlanDescription={plan} />
      {buttonPosition === "bottom" && actionButton}
    </div>
  );
};

export default PlanCard;
