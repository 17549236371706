import { type ElementRef, type SVGProps } from "react";

const BlueIllustration = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      width="170"
      height="171"
      viewBox="0 0 170 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g clipPath="url(#clip0_156_555)">
        <g opacity="0.2">
          <path
            d="M20.4951 33.704C6.63526 40.9754 4.82746 57.9418 25.3159 91.8747C32.547 114.699 25.1179 145.49 44.5 161.49C63.8821 177.49 83.8451 164.084 96.4226 154.287C109 144.49 119.5 128.99 147.041 119.748C174.582 110.507 162 77.4895 140.412 68.8488C118.825 60.208 122.334 16.1316 106.064 4.0127C89.794 -8.10619 74.1263 19.1614 63.8821 33.704C53.6379 48.2467 34.3549 26.4327 20.4951 33.704Z"
            fill="#7390F6"
          />
          <path
            d="M20.4951 33.704C6.63526 40.9754 4.82746 57.9418 25.3159 91.8747C32.547 114.699 25.1179 145.49 44.5 161.49C63.8821 177.49 83.8451 164.084 96.4226 154.287C109 144.49 119.5 128.99 147.041 119.748C174.582 110.507 162 77.4895 140.412 68.8488C118.825 60.208 122.334 16.1316 106.064 4.0127C89.794 -8.10619 74.1263 19.1614 63.8821 33.704C53.6379 48.2467 34.3549 26.4327 20.4951 33.704Z"
            fill="url(#pattern0_156_555)"
            style={{ mixBlendMode: "color-burn" }}
          />
        </g>
        <path
          d="M56.8403 39.138C61.1699 43.0338 67.7562 42.589 71.5513 38.1446C75.3464 33.7002 74.9131 26.9391 70.5835 23.0434C66.254 19.1476 59.6676 19.5924 55.8725 24.0368C52.0774 28.4812 52.5107 35.2423 56.8403 39.138Z"
          fill="white"
        />
        <path
          d="M42.7918 21.2342C44.3338 22.6217 46.6797 22.4633 48.0313 20.8803C49.383 19.2974 49.2287 16.8894 47.6866 15.5018C46.1446 14.1143 43.7988 14.2727 42.4471 15.8557C41.0954 17.4386 41.2498 19.8467 42.7918 21.2342Z"
          fill="white"
        />
        <path
          d="M87.5799 124.69C87.5799 124.69 107.737 110.051 113.103 101.024C113.103 101.024 117.28 123.495 134.2 128.724C134.2 128.724 123.064 148.422 114.124 155.985C115.23 154.999 90.3707 149.302 87.5799 124.69Z"
          fill="white"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.1621 133.971C97.2245 132.275 98.2888 130.58 99.3513 128.884C99.4211 128.772 99.4983 128.655 99.598 128.595C99.7152 128.526 99.8301 128.561 99.9284 128.599C100.334 128.764 100.71 128.985 101.051 129.256C101.605 128.766 102.006 128.058 102.465 127.434C102.924 126.81 103.544 126.204 104.12 126.192C104.326 126.188 104.523 126.258 104.738 126.159C104.968 126.054 105.151 125.787 105.314 125.54C106.191 124.205 107.067 122.873 107.942 121.537C108.873 120.119 109.841 118.616 110.178 117.044"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.2757 137.93C99.0207 136.894 99.8417 135.398 100.877 134.692C101.912 133.985 103.041 133.955 103.924 134.295C103.966 133.951 103.631 133.702 103.831 133.375C103.905 133.253 104.029 133.181 104.141 133.12C104.933 132.687 105.726 132.25 106.518 131.817"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M113.816 128.654C113.732 128.402 113.366 128.51 113.253 128.284C113.192 128.166 113.221 127.986 113.25 127.819C113.354 127.223 113.468 126.603 113.767 126.035C114.067 125.467 114.611 124.965 115.061 125.011C115.272 125.033 115.527 125.131 115.694 124.884C115.774 124.766 115.788 124.621 115.796 124.493C115.842 123.792 115.885 123.094 115.931 122.393"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M107.963 142.214C108.139 141.036 108.827 139.865 109.526 138.799C110.556 137.231 111.669 135.728 112.854 134.311C112.988 134.15 113.144 133.979 113.303 133.959C113.628 133.917 113.591 134.472 113.477 134.873C113.828 134.379 114.181 133.885 114.533 133.388"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M116.178 132.221C116.063 131.809 115.997 131.222 116.36 130.851C116.598 130.607 116.887 130.612 117.151 130.582C117.414 130.554 117.757 130.407 117.878 130.078C118.057 129.599 117.564 129.381 117.573 128.917C117.81 128.473 118.25 128.275 118.633 128.098C119.016 127.92 119.468 127.627 119.611 127.158C119.753 126.698 119.522 126.255 119.764 125.825"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.102 147.38C111.682 146.657 111.462 145.731 111.471 144.717C111.475 144.298 111.545 143.798 111.85 143.454C112.035 143.245 112.266 143.14 112.485 143.049C113.466 142.644 114.447 142.337 115.414 142.134C115.741 141.368 115.325 140.576 115.77 139.869C115.963 139.563 116.255 139.384 116.525 139.2C118.463 137.875 119.926 135.529 121.276 133.309C121.487 132.962 121.713 132.565 121.69 132.215C121.681 132.081 121.633 131.962 121.647 131.818C121.707 131.143 122.79 130.897 122.951 130.207"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.435 50.0615C52.435 50.0615 80.5158 74.1726 98.6642 83.8689C98.6642 83.8689 68.2886 119.904 62.9534 141.107C62.9534 141.107 32.735 120.791 6.57903 114.394C6.57645 114.396 19.0762 79.8013 52.435 50.0615Z"
          fill="#CFB6E2"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M49.2164 61.2918C61.6962 72.3927 76.9299 83.658 85.3906 87.8863L49.2164 61.2918Z" fill="#F9CA20" />
        <path
          d="M49.2164 61.2918C61.6962 72.3927 76.9299 83.658 85.3906 87.8863"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M43.557 67.7928C56.8848 79.2495 73.3304 90.6767 82.6223 94.758L43.557 67.7928Z" fill="#F9CA20" />
        <path
          d="M43.557 67.7928C56.8848 79.2495 73.3304 90.6767 82.6223 94.758"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M37.8959 74.2944C51.4941 86.0657 68.2371 97.8481 77.6607 102.104L37.8959 74.2944Z" fill="#F9CA20" />
        <path
          d="M37.8959 74.2944C51.4941 86.0657 68.2371 97.8481 77.6607 102.104"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M40.7756 92.1809C49.6921 100.334 61.4173 108.102 68.6711 110.485L40.7756 92.1809Z" fill="#F9CA20" />
        <path
          d="M40.7756 92.1809C49.6921 100.334 61.4173 108.102 68.6711 110.485"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M36.3485 101.476C45.7045 108.603 57.3534 116.114 64.3192 118.798L36.3485 101.476Z" fill="#F9CA20" />
        <path
          d="M36.3485 101.476C45.7045 108.603 57.3534 116.114 64.3192 118.798"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M31.0618 111.233C41.6307 116.968 52.9988 124.458 59.2016 127.734L31.0618 111.233Z" fill="#F9CA20" />
        <path
          d="M31.0618 111.233C41.6307 116.968 52.9988 124.458 59.2016 127.734"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="34.4641" cy="87.1811" r="2.65064" transform="rotate(47.4604 34.4641 87.1811)" fill="#311744" />
        <circle cx="27.9113" cy="96.791" r="2.65064" transform="rotate(47.4604 27.9113 96.791)" fill="#311744" />
        <circle cx="22.1867" cy="106.948" r="2.65064" transform="rotate(47.4604 22.1867 106.948)" fill="#311744" />
        <path
          d="M84.687 87.0622L90.6326 38.4599L131.763 13.1632L136.963 9.96478L142.962 10.1835L148.051 14.2192L144.868 63.0762L143.124 60.2406L135.199 57.9495L129.999 61.148L84.687 87.0622Z"
          fill="#7390F6"
        />
        <path
          d="M83.8655 86.2622L128.084 61.0934L132.143 13.8244L89.9219 38.5299L83.8655 86.2622Z"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.208 13.6758C141.34 5.27313 147.112 14.657 147.112 14.657L144.905 63.1856L141.912 85.5638L139.672 99.6194L137.092 99.8131L137.108 94.4738"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.13 61.1141C128.13 61.1141 137.287 53.9516 145.028 63.227"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.383 65.8555L99.7803 88.0124"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.203 69.5933L118.802 89.2489"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138.062 71.6963L128.715 96.0873"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M124.125 28.0027L96.3057 39.9307L94.1892 51.0852L124.743 38.4199L124.125 28.0027Z" fill="#311744" />
      </g>
    </svg>
  );
};

const GreenIllustration = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      width="170"
      height="171"
      viewBox="0 0 170 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g opacity="0.2">
        <path
          d="M82.1581 162.144C97.6375 173.415 124.055 173.553 133.62 162.144C143.186 150.735 132.519 120.213 142.958 105.449C153.398 90.6843 167.405 61.4876 155.337 53.2215C119.913 28.9579 154.1 14.8989 111.578 3.46099C69.0553 -7.97687 54.835 45.6658 51.6567 50.7136C48.4783 55.7614 31.1273 57.3188 13.5126 76.5195C-4.10201 95.7202 17.6769 123.751 29.6929 124.081C62.2789 124.976 66.6788 150.873 82.1581 162.144Z"
          fill="#37C78B"
        />
        <path
          d="M82.1581 162.144C97.6375 173.415 124.055 173.553 133.62 162.144C143.186 150.735 132.519 120.213 142.958 105.449C153.398 90.6843 167.405 61.4876 155.337 53.2215C119.913 28.9579 154.1 14.8989 111.578 3.46099C69.0553 -7.97687 54.835 45.6658 51.6567 50.7136C48.4783 55.7614 31.1273 57.3188 13.5126 76.5195C-4.10201 95.7202 17.6769 123.751 29.6929 124.081C62.2789 124.976 66.6788 150.873 82.1581 162.144Z"
          fill="url(#pattern0_156_594)"
          style={{ mixBlendMode: "color-burn" }}
        />
      </g>
      <path
        d="M115.78 101.449C123.873 86.4208 130.014 76.6754 140.742 72.2859C140.742 72.2859 112.709 69.1202 97.8844 90.4898"
        fill="white"
      />
      <path
        d="M115.78 101.449C123.873 86.4208 130.014 76.6754 140.742 72.2859C140.742 72.2859 112.709 69.1202 97.8844 90.4898"
        fill="#CFB6E2"
      />
      <path
        d="M115.78 101.449C123.873 86.4208 130.014 76.6754 140.742 72.2859C140.742 72.2859 112.709 69.1202 97.8844 90.4898"
        stroke="#2E1641"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        width="125.967"
        height="67.1994"
        rx="15.39"
        transform="matrix(0.99652 0.0833571 -0.0791324 0.996864 21.3184 81.5007)"
        fill="white"
      />
      <rect
        width="125.967"
        height="67.1994"
        rx="15.39"
        transform="matrix(0.99652 0.0833571 -0.0791324 0.996864 21.3184 81.5007)"
        fill="#CFB6E2"
      />
      <rect
        width="125.967"
        height="67.1994"
        rx="15.39"
        transform="matrix(0.99652 0.0833571 -0.0791324 0.996864 21.3184 81.5007)"
        stroke="#2E1641"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.7168 104.837L126.857 112.377"
        stroke="#2E1641"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8008 116.38L125.941 123.92"
        stroke="#2E1641"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8848 127.924L93.6366 132.852"
        stroke="#2E1641"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.191 43.6755C109.1 63.402 91.6889 80.9596 70.5547 79.6519C49.1738 78.3294 33.5656 68.3326 34.1351 46.9465C34.5876 29.9473 46.4353 17.9132 66.2499 14.6858C88.0758 11.1297 109.292 21.8573 109.191 43.6755Z"
        fill="#37C78B"
        stroke="#2E1641"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.4542 43.9147L61.6879 45.3584C59.0802 38.6196 62.5991 32.9337 68.9457 32.2196C77.5731 31.2488 79.8766 39.8406 76.8013 44.837C74.9665 47.8179 74.255 49.0798 75.376 52.8109L70.8075 54.2983C69.0034 50.5754 69.5781 47.4931 71.6008 44.1396C72.986 41.8429 72.458 37.4043 68.8939 37.6422C66.3416 37.8125 65.0718 40.8039 66.4542 43.9147Z"
        fill="#2E1641"
      />
      <path
        d="M75.4372 60.6572C77.3507 60.0799 78.5108 58.2454 78.0282 56.5598C77.5456 54.8742 75.6031 53.9757 73.6895 54.553C71.7759 55.1304 70.6158 56.9649 71.0985 58.6505C71.5811 60.3361 73.5236 61.2345 75.4372 60.6572Z"
        fill="#2E1641"
      />
      <path
        d="M51.7502 66.9915C51.1599 75.2747 48.9018 81.9816 39.7403 92.3064C39.7403 92.3064 56.3357 88.6286 62.6949 72.09"
        fill="#37C78B"
      />
      <path
        d="M51.7502 66.9915C51.1599 75.2747 48.9018 81.9816 39.7403 92.3064C39.7403 92.3064 56.3357 88.6286 62.6949 72.09"
        stroke="#2E1641"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.418 52.4041C135.748 56.2999 142.334 55.8551 146.129 51.4107C149.925 46.9663 149.491 40.2052 145.162 36.3095C140.832 32.4137 134.246 32.8585 130.451 37.3029C126.656 41.7473 127.089 48.5084 131.418 52.4041Z"
        fill="white"
      />
      <path
        d="M119.184 62.0603C120.726 63.4479 123.072 63.2895 124.424 61.7065C125.776 60.1236 125.621 57.7155 124.079 56.328C122.537 54.9405 120.191 55.0989 118.84 56.6818C117.488 58.2648 117.642 60.6728 119.184 62.0603Z"
        fill="white"
      />
    </svg>
  );
};

const YellowIllustration = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      width="170"
      height="171"
      viewBox="0 0 170 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g clipPath="url(#clip0_156_618)">
        <g opacity="0.2">
          <path
            d="M80.8888 152.505C95.2904 165.124 121.703 169.758 132.257 159.256C142.81 148.755 118.436 119.326 130.163 105.562C141.889 91.7973 162.994 84.7002 160.427 70.2994C153.737 32.7728 133.124 37.2437 91.8044 22.0235C50.4846 6.80321 47.7802 38.0866 44.1602 42.8277C40.5403 47.5688 13.1122 47.2037 6.40236 78.9248C1.01005 104.417 29.8831 121.392 39.3861 126.127C68.5633 140.665 66.4872 139.886 80.8888 152.505Z"
            fill="#F9CA20"
          />
          <path
            d="M80.8888 152.505C95.2904 165.124 121.703 169.758 132.257 159.256C142.81 148.755 118.436 119.326 130.163 105.562C141.889 91.7973 162.994 84.7002 160.427 70.2994C153.737 32.7728 133.124 37.2437 91.8044 22.0235C50.4846 6.80321 47.7802 38.0866 44.1602 42.8277C40.5403 47.5688 13.1122 47.2037 6.40236 78.9248C1.01005 104.417 29.8831 121.392 39.3861 126.127C68.5633 140.665 66.4872 139.886 80.8888 152.505Z"
            fill="url(#pattern0_156_618)"
            style={{ mixBlendMode: "color-burn" }}
          />
        </g>
        <path
          d="M73.1175 72.2829C65.126 65.8962 37.0989 65.2819 24.0842 65.7732C23.012 64.1647 19.9883 59.5903 16.4708 54.16C23.5075 31.5662 36.6981 13.9071 42.4138 7.90173L88.0239 10.8109C89.6273 12.257 93.4449 16.0081 95.8883 19.4434C77.0588 41.4582 79.1978 54.163 73.1175 72.2829Z"
          fill="#F9CA20"
        />
        <path
          d="M24.449 66.0195C24.4652 66.5193 24.4653 66.5193 24.4656 66.5193L24.4668 66.5192L24.4719 66.5191L24.4927 66.5184L24.576 66.516C24.6497 66.5138 24.7596 66.5108 24.9035 66.5072C25.1913 66.5 25.6152 66.4905 26.1586 66.4814C27.2453 66.4632 28.8098 66.4467 30.7187 66.4532C34.537 66.4662 39.7304 66.5715 45.2335 66.9402C50.7389 67.3092 56.542 67.941 61.5842 69.0041C66.6489 70.072 70.8609 71.5588 73.2574 73.5863L73.9124 74.1404L74.0717 73.2974L73.5804 73.2046C74.0717 73.2974 74.0717 73.2973 74.0717 73.2971L74.0719 73.296L74.0728 73.2914L74.0765 73.2721L74.0915 73.1944C74.1049 73.1255 74.1252 73.0225 74.1523 72.8871C74.2066 72.6165 74.2884 72.2164 74.3983 71.7007C74.6182 70.6693 74.9506 69.1753 75.3999 67.3281C76.2987 63.6331 77.6646 58.5272 79.5332 52.8843C83.2766 41.5795 89.0141 28.1926 97.0043 19.6459L97.3265 19.3013L97.0012 18.9597L88.5135 10.0443L88.3793 9.9034L88.1851 9.89024L41.9382 6.75618L41.6794 6.73864L41.5161 6.94012L41.9044 7.25503C41.5161 6.94012 41.5159 6.94027 41.5157 6.94054L41.5148 6.94165L41.5114 6.94586L41.4981 6.9623L41.4462 7.02676C41.4004 7.08362 41.3327 7.16802 41.2444 7.2787C41.0678 7.50004 40.8088 7.82647 40.4786 8.24776C39.8183 9.09031 38.8731 10.3124 37.7322 11.8321C35.4509 14.8712 32.3857 19.1025 29.2514 23.8707C26.1178 28.6378 22.9106 33.9484 20.3482 39.1451C17.7897 44.334 15.8562 49.4444 15.2996 53.8033L15.2749 53.9974L15.3883 54.1567L24.0417 66.3096L24.1972 66.528L24.4652 66.5193L24.449 66.0195ZM29.7384 26.4203C35.3199 17.7556 40.9395 10.542 42.4882 8.58382L87.5626 11.6344L94.8829 19.3235C86.8104 28.4563 81.1765 42.2456 77.8417 52.3241C75.1173 60.5467 73.4401 67.7115 72.7226 71.0169C69.9363 69.3265 66.0158 68.0922 61.6417 67.1847C56.9214 66.2053 51.6198 65.5964 46.5348 65.2223C36.7345 64.5015 27.6993 64.6505 25.1009 64.7166L17.139 53.5391C17.7152 49.7094 19.3932 45.2152 21.6553 40.5489C23.953 35.8091 26.8382 30.9224 29.7384 26.4203Z"
          fill="#311744"
          stroke="#311744"
        />
        <path
          d="M20.511 54.4796C20.511 54.4796 53.6872 52.5645 67.7408 58.1354C67.7408 58.1354 69.9024 36.0201 87.7374 10.8428"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
        />
        <path
          d="M37.0388 31.7576L40.6124 38.0642L48.6299 26.5725L45.2969 39.9211L41.8249 39.3378L38.9139 45.9813L60.7928 47.5175L61.2147 40.8124L51.2581 40.5661L59.8897 14.9123L56.188 13.5771L37.0388 31.7576Z"
          fill="#311744"
        />
        <path d="M87.3619 24.954C82.9791 31.5202 73.874 46.9632 72.516 56.2061" stroke="#311744" strokeWidth="3" />
        <path
          d="M96.8467 81.4351L73.0509 110.905L73.7467 122.909L95.1118 126.117L128.887 125.17L161.765 119.909L156.706 79.3397L166.051 35.5505L139.762 32.4666L105.824 34.7975L74.6966 43.0687L96.8467 81.4351Z"
          fill="#CFB6E2"
        />
        <path
          d="M75.4107 43.3344C75.4107 43.3344 122.11 25.7672 165.514 34.9239L157.102 78.8981L160.187 111.523C160.187 111.523 101.615 122.052 72.4773 110.335L96.9485 81.7931L75.4107 43.3344Z"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.8491 112.268L73.6292 122.917C73.6292 122.917 112.677 132.16 161.101 119.546L160.191 111.529"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.8516 81.1094C99.8516 81.1094 116.678 78.2409 157.11 78.8947"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.37 69.5093C131.442 74.4659 130.262 73.4963 126.565 71.2665C122.867 69.0297 123.422 72.6469 121.766 73.6703C120.118 74.6921 121.727 70.8652 120.033 69.0905C118.338 67.3089 118.224 70.7772 114.255 72.6651C110.287 74.5529 107.76 61.4175 107.76 61.4175C107.76 61.4175 109.579 71.6059 99.972 64.5386C90.3716 57.4696 97.8371 43.8085 97.8371 43.8085C97.8371 43.8085 100.432 38.8093 103.307 41.5604C106.182 44.3116 103.746 48.639 108.069 48.2282C112.399 47.8156 116.597 38.9911 118.022 44.8974C119.447 50.8037 113.939 62.1234 120.079 60.0242C126.212 57.9266 125.309 52.8308 129.833 54.5601C134.356 56.2894 134.641 64.0313 140.812 64.7319"
          fill="white"
        />
        <path
          d="M99.6191 46.6483C99.6191 46.6483 106.736 71.654 148.469 65.0681"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.37 69.5093C131.442 74.4659 130.262 73.4963 126.565 71.2665C122.867 69.0297 123.422 72.6469 121.767 73.6703C120.118 74.6922 121.727 70.8652 120.033 69.0905C118.338 67.3089 118.224 70.7772 114.255 72.6651C110.287 74.5529 107.76 61.4175 107.76 61.4175C107.76 61.4175 109.579 71.6059 99.972 64.5386C90.3716 57.4696 97.8371 43.8085 97.8371 43.8085C97.8371 43.8085 100.432 38.8093 103.307 41.5604C106.182 44.3116 103.746 48.639 108.069 48.2282C112.399 47.8156 116.597 38.9911 118.022 44.8974C119.447 50.8037 113.939 62.1234 120.079 60.0242C126.212 57.9266 125.309 52.8308 129.833 54.5601C134.356 56.2894 134.641 64.0313 140.812 64.7319"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.2305 104.376C90.2305 104.376 121.891 108.849 147.269 105.633"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M121.205 88.5273C127.544 88.7509 134.91 88.5727 142.92 87.5577"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.4258 95.8267C91.4258 95.8267 108.7 100.111 135.77 98.2971"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M112.19 86.3615C112.19 86.3615 105.03 101.359 100.707 108.959"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.319 93.2442C117.319 93.2442 114.75 102.094 110.259 109.605"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.712 83.5516C128.712 83.5516 127.722 101.488 122.997 111.523"
          stroke="#311744"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.846 149.909C80.4665 146.333 88.5503 132.168 84.9016 118.269C81.2529 104.371 67.2534 96.0025 53.6329 99.5782C40.0123 103.154 31.9286 117.32 35.5773 131.218C39.226 145.117 53.2254 153.485 66.846 149.909Z"
          fill="#F9CA20"
        />
        <path
          d="M65.8589 150.168C65.8589 150.168 51.1608 134.234 47.6375 127.266C46.9436 125.901 45.3338 119.858 46.6853 119.178C48.9574 118.05 53.12 125.224 55.5396 124.271C57.1813 123.626 55.8285 116.659 57.6293 115.401C59.3176 114.22 62.4232 121.084 64.2187 120.073C65.945 119.096 63.2519 112.346 65.1034 111.638C67.2684 110.816 72.1942 116.258 72.9949 118.475C76.0256 126.897 77.1581 144.473 77.1581 144.473"
          stroke="white"
          strokeWidth="3.27321"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.92 143.812C83.4309 144.787 83.3496 145.916 82.8056 146.794C84.1443 146.454 85.5919 147.053 86.2542 148.316C86.8001 149.357 86.67 150.575 86.0216 151.474C87.0698 151.52 88.0646 152.101 88.5814 153.087C89.3546 154.562 88.7788 156.39 87.2805 157.166L85.6493 158.013C86.301 160.755 84.6472 163.529 81.9075 164.248C79.9458 164.763 77.9517 164.087 76.6914 162.667L73.8914 164.122C72.4005 164.896 70.5569 164.326 69.7837 162.852C69.2379 161.811 69.368 160.592 70.0163 159.693C68.968 159.647 67.973 159.066 67.4562 158.08C66.9453 157.106 67.0266 155.976 67.5706 155.099C66.2319 155.439 64.7843 154.84 64.122 153.577C63.3488 152.103 63.9319 150.272 65.4229 149.498L78.8122 142.542C80.3032 141.768 82.1468 142.338 82.92 143.812Z"
          fill="#311744"
        />
        <path
          d="M27.5556 123.849L12 121.177M31.1111 111.825L16.8889 104.255M38.2222 100.247L31.1111 88.2239M52 94.0129V81.9895M68 96.2395L72 84.2161M26.2222 136.763L18.6667 138.99"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

const RedIllustration = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg
      width="170"
      height="171"
      viewBox="0 0 170 171"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g opacity="0.2">
        <path
          d="M81.8144 159.208C98.5555 171.397 127.126 171.547 137.471 159.208C147.816 146.868 136.28 113.859 147.571 97.8914C158.861 81.9236 167.083 66.6043 160.958 41.4073C154.833 16.2102 133.556 7.08888 94.6216 22.7707C55.6873 38.4526 75.6952 -0.481664 37.8424 3.84435C-0.0103262 8.17039 26.6243 45.8386 7.57388 66.6043C-11.4765 87.37 12.0776 117.686 25.0729 118.043C60.315 119.01 65.0734 147.018 81.8144 159.208Z"
          fill="#FB7268"
        />
        <path
          d="M81.8144 159.208C98.5555 171.397 127.126 171.547 137.471 159.208C147.816 146.868 136.28 113.859 147.571 97.8914C158.861 81.9236 167.083 66.6043 160.958 41.4073C154.833 16.2102 133.556 7.08888 94.6216 22.7707C55.6873 38.4526 75.6952 -0.481664 37.8424 3.84435C-0.0103262 8.17039 26.6243 45.8386 7.57388 66.6043C-11.4765 87.37 12.0776 117.686 25.0729 118.043C60.315 119.01 65.0734 147.018 81.8144 159.208Z"
          fill="url(#pattern0_166_896)"
          style={{ mixBlendMode: "color-burn" }}
        />
      </g>
      <path
        d="M34 143.49L6 135.99V125.99L17.5 119.99L29 111.49L31.5 93.9895L37 40.9895L41 38.9895H69.5L88.5 43.4895L95.5 44.4895L109.5 46.4895L118 49.9895L121.5 50.9895L125.5 51.9895L141.5 56.4895V64.4895L119.5 121.49L121.5 132.99L128 148.99L125.5 156.99L88.5 151.49L70.5 148.99H58L34 143.49Z"
        fill="#FB7268"
      />
      <path
        d="M28.5 124.49C42.1 127.69 58.1667 131.49 64.5 132.99M34.5 119.99C41.8333 121.49 57.3 124.69 60.5 125.49M53 134.49C53.8333 129.99 55.4 120.79 55 119.99"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31 128.99C39.4 126.99 44.5 120.49 46 117.49"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87 67.4895C86.6224 72.053 86.1327 82.7548 85.8238 85.1543C85.3088 89.1633 83.6659 92.8877 76.604 92.2449C69.542 91.5971 56.3303 89.0357 52.5443 88.5548C48.7534 88.0739 46.2081 85.4683 46.5269 81.1992C46.8457 76.9302 49.5184 61.6793 49.9009 58.632C50.2884 55.5799 53.2995 53.2147 56.9482 53.5582C56.9482 53.5582 83.8903 57.1946 88 57.9895"
        fill="white"
      />
      <path
        d="M31.5156 94.3307L34.2423 70.0608"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2411 110.583L29.7734 109.788L30.3472 104.66"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.038 57.0815C130.373 58.2592 136.464 60.3938 140.829 63.8924L119.628 122.928L88.7422 118.414"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.4375 59.6528L37.4384 41.6099C45.8539 41.5363 98.499 48.4895 124.499 56.9895"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89 151.99C56.5 149.49 11.7947 138.106 5.10547 135.495V125.804C5.10547 125.804 23.5794 119.037 29.7733 109.792"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.627 122.929C119.627 122.929 120.986 138.812 128.087 149.784L125.478 156.056C125.478 156.056 110.05 154.608 88.7754 151.919"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.501 143.49C60.001 141.49 15.6711 130.053 11.125 128.625"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.088 149.784C128.088 149.784 113.783 147.738 92.9258 144.544"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7421 62.1606C58.7171 62.1606 59.5075 61.3697 59.5075 60.3941C59.5075 59.4185 58.7171 58.6276 57.7421 58.6276C56.767 58.6276 55.9766 59.4185 55.9766 60.3941C55.9766 61.3697 56.767 62.1606 57.7421 62.1606Z"
        fill="#311744"
      />
      <path
        d="M55.996 71.7194C56.971 71.7194 57.7615 70.9285 57.7615 69.9529C57.7615 68.9773 56.971 68.1864 55.996 68.1864C55.0209 68.1864 54.2305 68.9773 54.2305 69.9529C54.2305 70.9285 55.0209 71.7194 55.996 71.7194Z"
        fill="#311744"
      />
      <path
        d="M53.7889 81.3077C54.764 81.3077 55.5544 80.5168 55.5544 79.5412C55.5544 78.5656 54.764 77.7747 53.7889 77.7747C52.8139 77.7747 52.0234 78.5656 52.0234 79.5412C52.0234 80.5168 52.8139 81.3077 53.7889 81.3077Z"
        fill="#311744"
      />
      <path
        d="M63.9414 61.8219C63.9414 61.8219 71.1456 64.2263 79.9681 64.6925"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.793 71.7782C61.793 71.7782 71.8268 74.3102 79.1781 74.3887"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.6836 81.3516C59.6836 81.3516 68.8739 83.3634 78.3635 83.0297"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.0183 87.7417C91.556 87.6926 88.9421 89.518 89.001 92.7861C89.05 95.4947 91.0999 97.909 94.7879 97.7372C93.1695 103.689 104.258 109.028 106.102 98.964C105.817 103.606 108.034 104.592 109.142 104.592C111.634 104.592 112.595 101.987 112.286 98.964C112.286 98.964 116.033 100.22 118.803 100.681C121.574 101.143 122.545 100.74 123.703 97.7372C124.855 94.7342 126.885 86.358 128.043 83.3549C129.195 80.3518 129.195 78.7129 125.272 78.1486C121.349 77.5794 117.19 75.9895 117.19 75.9895"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.102 98.9639L108.642 85.3274"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.191 82.2213C112.191 82.2213 119.474 82.6629 123.956 85.3274"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.184 87.6925C111.184 87.6925 117.892 87.9967 122.017 89.8319"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.203 92.8105C110.203 92.8105 113.097 92.943 116.25 93.5515"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.7363 90.8135C99.7363 90.8135 104.135 94.003 101.933 99.3614"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.3145 101.737C94.3145 101.737 97.4973 101.811 98.7919 99.9462"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.657 75.3294C113.543 74.331 111.766 74.1299 110.14 75.7211C109.389 73.3325 106.558 71.1027 103.018 72.3446C101.106 73.015 98.3701 75.7211 100.299 79.4574C95.1649 77.7744 91.0098 82.9895 94.0095 87.4895"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.674 92.434L110.136 75.7211"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.911 81.7505C104.911 81.7505 103.873 86.2325 98.5096 85.4897"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.13 41.816L40.5 94.7182"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.816 51.9097C131.511 53.2591 133.565 54.2258 142.398 57.4398L140.828 63.8925"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4395 41.61L41.4707 38.4843C41.4707 38.4843 82 38.4843 124.5 51.9895"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.8135 70.2471C36.8135 70.2471 8.19527 69.1557 6.40097 68.891C4.60667 68.6264 3.80465 67.7563 4.05732 66.1039C4.31 64.4515 4.03715 61.0308 4.20715 59.3525C4.37715 57.6742 5.03761 56.0949 8.10818 56.2492C11.1788 56.4034 36.3165 57.8764 37.9664 58.0133C39.6163 58.1501 40.7564 59.1969 40.677 60.98C40.5975 62.763 39.9246 66.7389 39.8002 68.0144C39.6764 69.2956 38.4006 70.3276 36.8135 70.2471Z"
        fill="white"
      />
      <path
        d="M31.9198 63.358L27.5099 63.7637L25.7632 61.2752L18.4299 64.5864L18.1881 61.9673L12.6495 62.471L8.20703 61.0209"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.277 64.6186C130.277 64.6186 101.682 66.206 99.8712 66.1102C98.0601 66.0143 97.1803 65.223 97.2774 63.5542C97.3746 61.8854 96.7833 58.5052 96.7958 56.8184C96.8082 55.1315 97.3182 53.4973 100.39 53.3641C103.461 53.2308 128.627 52.3485 130.282 52.3306C131.938 52.3126 133.171 53.2484 133.258 55.0311C133.346 56.8137 133.047 60.8351 133.042 62.1167C133.039 63.4037 131.865 64.5504 130.277 64.6186Z"
        fill="white"
      />
      <path
        d="M124.763 58.2168L120.41 59.0328L118.439 56.7184L111.447 60.7003L110.961 58.1153L105.494 59.1343L100.936 58.1056"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44 99.9895L78.6624 104.828"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 106.99L81.5661 112.044"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43 112.907L63.362 116.342"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.5488 136.506L118.44 139.49"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.7988 128.621L117.24 132.124"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.507 125.789C110.507 125.789 110.227 136.084 113.273 142.92"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.252 123.557C103.252 123.557 102.987 128.954 104.179 134.357"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.801 94.7972L161.162 113.169"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.162 94.7972L142.801 113.169"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.98 90.9895V116.972"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139 103.983H164.967"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.229 41.8994L157.889 49.5592"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.889 41.8994L150.229 49.5592"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.265 105.863C37.265 105.863 9.10608 100.641 7.36897 100.119C5.63187 99.5977 4.96422 98.6207 5.45341 97.0222C5.94259 95.4238 8.71182 82.5457 9.12293 80.9097C9.53405 79.2737 10.4161 77.8065 13.4321 78.4036C16.448 79.0007 41.1078 84.0965 42.7206 84.4707C44.3333 84.8449 45.3099 86.0457 44.9732 87.7985C44.6365 89.5513 40.8511 102.842 40.5434 104.086C40.2354 105.336 38.8237 106.172 37.265 105.863Z"
        fill="white"
      />
      <path
        d="M35.9647 88.7878L31.5425 88.551L30.1743 85.8358L22.439 88.0508L22.5789 85.4243L17.0256 85.121L12.8398 83.0432"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 92.9895L16.5 97.4895L21 95.4895L22.5 98.4895L28 96.9895L34 100.99L38 98.9895"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.051 82.2035L90.134 89.488C55.0804 92.7465 57.0778 119.872 73.2669 129.75C76.2104 132.139 85.1958 136.356 88.7924 138.474C69.908 123.456 84.7516 104.856 94.6457 104.298L95.272 111.683L112.515 93.3212L86.051 82.2035Z"
        fill="#CFB6E2"
        stroke="#311744"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { BlueIllustration, GreenIllustration, YellowIllustration, RedIllustration };
