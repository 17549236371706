import { type ElementRef, type SVGProps } from "react";

const ThreeBooks = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="131" height="101" viewBox="0 0 131 101" fill="none" {...props}>
      <g clipPath="url(#clip0_156_1896)">
        <g opacity="0.7">
          <path
            d="M37.7148 48.7567C40.9764 36.0124 48.5288 18.0349 57.6776 4.49463C57.6776 4.49463 84.255 20.8756 98.1521 27.5497C98.1521 27.5497 73.7567 47.5217 72.1099 75.0185C72.1099 75.0185 59.4342 72.7587 51.2323 70.5905"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M54.7812 24.4346C57.1554 25.0338 59.5295 25.6331 61.8991 26.2323C62.0546 26.2735 62.2193 26.3192 62.3199 26.4427C62.4388 26.5891 62.4297 26.7995 62.4068 26.9871C62.3245 27.7602 62.1644 28.5241 61.9311 29.2652C62.6904 29.8415 63.6648 30.0245 64.5614 30.3539C65.4534 30.6832 66.3683 31.2916 66.5512 32.2248C66.6153 32.5587 66.5878 32.9293 66.7708 33.2175C66.9675 33.5194 67.3426 33.6429 67.6903 33.7435C69.5658 34.2787 71.4459 34.8185 73.3214 35.3537C75.3158 35.9255 77.4246 36.4973 79.442 35.9941"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M86.4221 31.0441C83.4853 30.3213 80.5486 29.5986 77.6118 28.8712C77.4197 28.8255 77.2138 28.766 77.0903 28.6151C76.9439 28.4367 76.9576 28.1805 76.9851 27.9472C77.0903 26.9957 77.2961 26.058 77.5889 25.1477C76.6512 24.4432 75.4435 24.2236 74.3365 23.8257C73.2295 23.4231 69.7163 24.0864 69.4968 22.9382C69.419 22.5265 69.4556 22.0736 69.2314 21.7214C68.989 21.3509 68.5224 21.2045 68.0924 21.081C65.7686 20.4314 65.8326 18.3821 63.5088 17.7325C61.0386 17.0418 58.4267 16.3465 55.9336 16.9777"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.3828 33.922C50.2611 32.6274 52.2464 32.4399 53.5776 33.2633C54.9087 34.0867 55.6269 35.6511 55.8282 37.2064C56.1987 36.9548 56.2399 36.2595 56.6836 36.2458C56.8483 36.2412 56.9947 36.351 57.1273 36.4562C58.0422 37.1836 58.9571 37.9109 59.872 38.6382"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.3066 40.2939C62.6863 40.1063 62.6634 39.5071 63.0157 39.2784C63.2032 39.1548 63.4411 39.1686 63.6652 39.1914C64.4612 39.2601 65.2846 39.3378 65.9753 39.7358C66.6661 40.1383 67.1784 40.9617 66.9588 41.7302C66.8582 42.087 66.634 42.5353 66.9177 42.7732C67.0549 42.8876 67.2516 42.883 67.43 42.8738C68.3906 42.8235 69.3513 42.7732 70.3119 42.7183"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.752 42.0092C47.2478 41.5061 48.8854 41.8583 50.3996 42.3066C52.6227 42.9607 53.3546 43.6103 55.436 44.6258C55.6693 44.7402 55.93 44.8866 55.9987 45.1381C56.145 45.6505 56.8953 46.1537 56.3692 46.236C57.0782 46.4876 57.7873 46.7392 58.5009 46.9954"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M64.0625 49.4478C64.5337 48.9721 65.2381 48.4643 65.8054 48.8211C66.1759 49.059 66.2582 49.553 66.3726 49.9739C66.4869 50.3993 66.7706 50.8796 67.2097 50.8613C67.8547 50.8339 67.9736 49.8549 68.5454 49.5576C69.163 49.6537 69.5381 50.2621 69.872 50.7927C70.206 51.3188 70.7 51.886 71.3221 51.8082C71.9305 51.735 72.4063 51.0443 73.0101 51.1587"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.8398 50.8291C44.6038 49.6397 45.6742 48.6471 46.923 47.9746C47.4353 47.6956 48.0712 47.476 48.5835 47.7505C48.8946 47.9152 49.0913 48.2308 49.2651 48.5373C50.0519 49.9005 50.7152 51.3277 51.2504 52.8098C52.2842 52.8372 53.1351 51.6067 54.1323 51.8675C54.5623 51.9818 54.8688 52.3478 55.1753 52.6726C57.371 55.0101 60.6783 55.8655 63.7981 56.6111C64.2875 56.7301 64.841 56.8353 65.2619 56.5608C65.4265 56.4556 65.5546 56.2955 65.7376 56.2177C66.5793 55.8609 67.2014 57.5032 68.098 57.3065"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.0352 57.5309C43.6207 56.616 44.9518 56.4833 45.9445 56.9408C46.9371 57.3982 47.6736 58.2856 48.3735 59.1456C49.2381 60.2115 50.1072 61.2819 50.9718 62.3477C50.7476 61.6021 51.4292 60.8519 52.1703 60.7284C52.9113 60.6049 53.6615 60.9159 54.366 61.2224C56.4748 62.1373 58.6614 63.1071 60.1984 64.8591C60.7702 63.2809 62.9339 62.613 64.3108 63.5965"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.6961 97.4843L35.6143 66.3553L14.5628 32.2942L2.5 62.371L22.6961 97.4843Z"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5625 32.2942C15.9211 24.2157 22.737 26.7774 22.737 26.7774L45.2294 60.6647L52.7406 73.5509L59.0716 83.6695L56.1349 85.1882L52.7818 80.6229"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.6133 66.3553C35.6133 66.3553 37.4705 58.9722 45.2241 60.6647"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M37.7373 68.8254L31.7539 92.0544"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M41.2461 69.2329L42.0512 84.9369"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M45.1055 67.8875L50.023 84.424"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M17.5995 44.2559L7.89258 61.7896L11.3097 68.4866L22.1511 49.4524L17.5995 44.2559Z" fill="#9E6DC5" />
          <path d="M80.8203 64.0136L97.4849 40.2678L119.799 21.842L102.183 55.894L80.8203 64.0136Z" fill="#9E6DC5" />
          <path
            d="M126.686 56.9452L129.249 58.2821L110.847 88.9856C110.847 88.9856 84.7312 80.0838 81.0854 73.7025C77.4396 67.3211 80.8246 64.0093 80.8246 64.0093M80.8246 64.0093L102.187 55.8942L119.803 21.8376L97.4893 40.2634L80.8246 64.0093Z"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M83.8574 72.6503L107.96 82.6866L128.559 53.8998L122.611 53.1836"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M84.2012 69.041L106.158 76.6529L124.941 49.5311L121.195 48.6711"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M86.1406 66.4335L102.888 66.7583L124.827 44.0737L107.055 46.4753"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M91.0938 61.8134C91.5786 61.9003 92.0635 61.9872 92.5484 62.0787C93.0379 62.1656 96.4595 61.388 96.3955 61.882C96.8941 61.6762 98.5958 60.9854 98.948 61.0769C99.7028 61.2782 99.7943 62.2983 99.7257 63.0759C100.764 62.7877 101.802 62.4996 102.841 62.2114"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M102.744 57.898C102.68 58.1359 102.621 58.3737 102.557 58.6116C105.443 56.4388 106.632 57.7745 106.706 58.2548C107.406 58.1313 108.105 58.0124 108.805 57.8888"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M105.824 53.4328C107.709 52.4035 108.697 51.2508 110.028 49.5674C109.941 50.3999 110.682 51.8683 110.595 52.7009C111.067 52.4493 112.475 51.1364 113.226 50.6652C113.102 51.1501 113.299 52.5133 113.175 52.9936"
            stroke="#9E6DC5"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_156_1896">
          <rect width="130" height="100" fill="white" transform="translate(0.5 0.494629)" />
        </clipPath>
      </defs>
    </svg>
  );
};
const DropdownArrow = ({ fill = "#311744", props }: { fill?: string; props?: SVGProps<ElementRef<"svg">> }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.97221 10.9659C8.84719 11.0908 8.67766 11.1611 8.50088 11.1611C8.3241 11.1611 8.15456 11.0908 8.02955 10.9659L4.25821 7.19453C4.19454 7.13303 4.14375 7.05947 4.10881 6.97814C4.07387 6.8968 4.05548 6.80932 4.05471 6.7208C4.05394 6.63228 4.07081 6.54449 4.10433 6.46256C4.13785 6.38063 4.18735 6.3062 4.24995 6.2436C4.31254 6.18101 4.38698 6.1315 4.46891 6.09798C4.55084 6.06446 4.63863 6.0476 4.72715 6.04837C4.81567 6.04913 4.90315 6.06753 4.98448 6.10246C5.06582 6.1374 5.13938 6.18819 5.20088 6.25187L8.50088 9.55187L11.8009 6.25187C11.9266 6.13043 12.095 6.06323 12.2698 6.06475C12.4446 6.06627 12.6118 6.13638 12.7354 6.25999C12.859 6.38359 12.9291 6.5508 12.9307 6.7256C12.9322 6.9004 12.865 7.0688 12.7435 7.19453L8.97221 10.9659Z"
        fill={fill}
      />
    </svg>
  );
};

const UploadDesignBehind = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg width="670" height="407" viewBox="0 0 670 407" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M64.9787 145.208C65.7396 145.535 66.622 145.184 66.9495 144.423C67.277 143.662 66.9257 142.78 66.1648 142.452L64.9787 145.208ZM31.2901 177.285L30.6514 178.643L31.2901 177.285ZM45.9645 157.818L44.9937 158.962L45.9645 157.818ZM1.17802 176.164C1.22953 176.99 1.94157 177.619 2.76839 177.567L16.2423 176.728C17.0691 176.676 17.6976 175.964 17.6461 175.138C17.5946 174.311 16.8825 173.682 16.0557 173.734L4.07894 174.48L3.33273 162.503C3.28122 161.676 2.56918 161.048 1.74236 161.099C0.915537 161.151 0.287024 161.863 0.338539 162.69L1.17802 176.164ZM66.1648 142.452C50.896 135.88 38.0952 143.031 31.0834 152.504C27.5865 157.229 25.4343 162.638 25.1185 167.401C24.8032 172.159 26.341 176.614 30.6514 178.643L31.9288 175.928C29.1669 174.629 27.8413 171.683 28.112 167.6C28.3823 163.523 30.2657 158.652 33.4947 154.289C39.9347 145.589 51.347 139.34 64.9787 145.208L66.1648 142.452ZM30.6514 178.643C37.9588 182.082 45.5918 179.788 49.638 174.999C51.6714 172.592 52.8229 169.521 52.5038 166.235C52.1838 162.941 50.4055 159.621 46.9354 156.675L44.9937 158.962C47.9943 161.509 49.2877 164.156 49.5178 166.525C49.7487 168.902 48.9288 171.19 47.3464 173.063C44.1614 176.833 37.9863 178.779 31.9288 175.928L30.6514 178.643ZM46.9354 156.675C42.4061 152.829 37.9132 151.176 33.5062 151.208C29.1318 151.241 25.0313 152.934 21.2549 155.428C13.7766 160.368 7.07855 168.815 1.55055 175.078L3.79968 177.063C9.52553 170.576 15.8609 162.586 22.9083 157.932C26.3946 155.629 29.93 154.235 33.5286 154.208C37.0945 154.182 40.9117 155.496 44.9937 158.962L46.9354 156.675Z"
        fill="#CFB6E2"
      />
      <path
        d="M587.942 24.4788C587.602 23.7233 586.714 23.3866 585.958 23.7266L573.648 29.2674C572.892 29.6074 572.556 30.4955 572.896 31.2509C573.236 32.0063 574.124 32.3431 574.879 32.0031L585.822 27.0779L590.747 38.0206C591.087 38.776 591.975 39.1128 592.731 38.7728C593.486 38.4328 593.823 37.5447 593.483 36.7893L587.942 24.4788ZM519.234 51.5203C526.987 40.3108 533.805 36.2499 539.779 35.3851C545.835 34.5086 551.411 36.8338 556.956 39.3687C559.671 40.61 562.418 41.9167 565.08 42.7699C567.759 43.6285 570.506 44.0832 573.252 43.5154C578.845 42.3589 583.594 37.1836 587.977 25.6263L585.171 24.5625C580.851 35.9563 576.615 39.7565 572.644 40.5775C570.608 40.9985 568.428 40.6925 565.996 39.913C563.547 39.1282 561.001 37.9195 558.203 36.6402C552.72 34.134 546.387 31.3975 539.35 32.4161C532.232 33.4463 524.774 38.2372 516.767 49.8138L519.234 51.5203Z"
        fill="#B691D4"
      />
      <path
        d="M186.819 216.524C186.819 216.524 143.851 247.17 124.238 268.984C124.238 268.984 84.6158 214.638 57.0389 200.014C57.0389 200.014 95.4757 165.114 113.242 131.084C113.242 131.084 156.943 160.194 186.819 216.524Z"
        fill="white"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M553.454 61.6995C553.454 61.6995 593.49 96.088 619.363 109.913C619.363 109.913 576.075 161.386 568.474 191.661C568.474 191.661 525.391 162.69 488.099 153.582C488.099 153.582 505.908 104.185 553.454 61.6995Z"
        fill="white"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M56.9096 101.318C56.9096 101.318 117.29 59.4334 143.094 28.6741C143.094 28.6741 217.32 112.929 263.771 137.059C263.771 137.059 211.44 185.662 191.27 234.751C191.27 234.751 116.326 187.684 56.9096 101.318Z"
        fill="#FB7268"
      />
      <path
        d="M88.7383 119.489C110.326 99.5756 131.223 82.5732 149.264 59.8049"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.369 131.185C121.957 111.272 142.854 94.2693 160.894 71.501"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.998 142.881C133.586 122.967 154.483 105.965 172.523 83.1968"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.627 154.577C145.215 134.664 166.112 117.661 184.152 94.8931"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.256 166.274C156.844 146.36 177.741 129.358 195.781 106.589"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.885 177.97C168.472 158.056 189.37 141.054 207.41 118.285"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.514 189.666C180.101 169.752 200.999 152.75 219.039 129.982"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M487.009 242.07L558.584 203.674L637.588 185.665L550.651 248.634L487.009 242.07Z" fill="#37C78B" />
      <path
        d="M611.922 281.401L615.345 288.639L530.658 342.263C530.658 342.263 476.536 287.192 475.447 266.646C474.359 246.1 486.996 242.089 486.996 242.089L550.643 248.653L637.589 185.663L558.579 203.675L495.219 237.185"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M483.74 267.497L531.386 322.885L619.144 276.83L605.138 267.458"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M489.119 258.915L534.468 305.535L615.592 261.357L607.303 254.493"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M497.272 254.824L538.749 276.69L622.174 247.571L574.716 231.226"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M515.468 249.506C516.566 250.339 517.665 251.16 518.776 251.996C519.887 252.831 529.417 255.19 528.641 256.332C530.149 256.449 535.271 256.849 536.039 257.52C537.678 258.976 536.615 261.645 535.47 263.495C538.436 264.084 541.388 264.672 544.353 265.261"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M549.55 254.364C549.097 254.883 548.645 255.39 548.179 255.908C558.127 254.103 559.429 258.934 559.012 260.229C560.91 260.797 562.82 261.38 564.718 261.949"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M562.859 247.072C568.868 246.869 572.786 245.227 578.239 242.688C576.979 244.656 576.977 249.262 575.717 251.229C577.215 251.191 582.382 249.678 584.865 249.445C583.945 250.495 582.716 254.151 581.809 255.203"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M97.3674 400.1L94.1766 390.875L109.143 348.514L186.329 325.95L212.182 350.061L215.373 359.287L200.768 367.695L142.57 387.823L97.3674 400.1Z"
        fill="white"
      />
      <path
        d="M151.764 297.398C152.599 297.208 153.561 296.983 154.602 296.731C156.876 296.181 159.484 295.39 161.982 294.633C163.261 294.245 164.511 293.866 165.673 293.533C169.3 292.491 172.207 291.263 173.931 288.579C175.565 286.037 175.809 282.646 175.824 278.432L175.824 278.432C175.831 276.628 175.853 275.083 175.875 273.579C175.964 267.473 176.042 262.058 175.012 242.877L175.012 242.877C174.616 235.517 171.473 228.957 166.791 226.126C163.903 224.375 160.514 223.705 157.229 223.59C153.928 223.474 150.569 223.912 147.6 224.522C144.622 225.133 141.969 225.932 140.064 226.575C139.925 226.622 139.79 226.669 139.659 226.714C137.458 219.406 131.906 213.484 123.402 213.242C123.24 213.238 123.079 213.249 122.92 213.276C117.527 214.182 113.552 217.007 110.854 221.024C105.634 217.339 98.226 215.759 91.3159 218.149C91.2373 218.176 91.1601 218.208 91.0846 218.243C81.7716 222.552 74.8689 231.528 75.3532 242.607C75.3566 242.684 75.3636 242.761 75.3742 242.838C75.8905 246.58 77.5373 250.519 80.0495 253.618C75.855 255.166 72.7218 258.154 70.8684 261.839C68.3826 266.781 68.2359 272.887 70.6823 278.322C70.6937 278.347 70.7055 278.372 70.7177 278.397C73.3969 283.854 78.1905 288.718 84.1481 291.053C83.0488 292.252 82.1986 293.602 81.4831 294.841C81.4412 294.914 81.4029 294.988 81.3685 295.065C79.7396 298.691 80.0701 302.938 82.2602 307.332C84.1463 311.121 87.3751 314.1 91.4731 315.422C94.816 316.501 98.5895 316.431 102.477 314.984C104.784 320.591 110.27 323.952 115.832 324.928C122.309 326.063 129.816 324.129 133.667 317.834L133.668 317.832C134.802 315.973 135.578 313.837 135.933 311.473C136.964 312.398 138.033 313.112 139.115 313.637C142.378 315.245 145.45 314.832 147.402 313.908L147.407 313.905C151.382 312.011 153.117 308.502 153.169 304.634C153.203 302.148 152.716 299.72 151.764 297.398Z"
        fill="#733E9D"
        stroke="#311744"
        strokeWidth="4.96795"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.955 230.018C136.598 222.217 131.446 215.999 123.372 215.792C117.702 216.753 113.853 220.084 111.514 224.893C106.981 220.461 99.1818 218.159 92.0342 220.631C83.4085 224.614 77.2208 232.754 77.6295 242.612C78.2806 247.387 81.0275 252.437 84.9435 255.082C73.2953 256.111 68.3432 267.824 72.6346 277.339C75.9993 284.175 82.9379 289.775 91.0122 289.983C87.1295 290.326 85.0669 293.039 83.3156 296.044C82.0549 298.82 82.1949 302.262 84.1426 306.158C87.3687 312.622 95.0814 316.065 103.768 311.42C105.972 323.547 124.991 326.649 131.364 316.325C133.484 312.882 134.247 308.118 132.691 302.347C134.815 307.332 137.538 309.97 140.069 311.185C142.597 312.42 144.883 312.059 146.225 311.426C149.231 309.996 150.557 307.437 150.607 304.37C150.656 301.303 149.777 298.297 148.035 295.46C148.035 295.46 150.582 294.929 153.978 294.104C157.374 293.28 161.396 291.949 164.998 290.913C172.204 288.84 173.362 286.78 173.416 278.201C173.473 269.082 173.918 266.638 172.694 242.882C172.34 236.015 169.414 230.417 165.679 228.179C156.056 222.397 137.955 230.018 137.955 230.018Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.739 302.57C126.442 276.728 119.271 250.209 111.99 224.878"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.812 257.96C139.68 253.059 151.947 249.017 161.917 246.578"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.052 269.061C143.855 264.837 153.744 261.326 163.3 258.731"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.057 280.73C138.057 280.73 146.652 277.007 155.711 274.624"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.055 288.472C114.413 288.921 120.458 294.83 122.044 303.002"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.516 251.072C111.297 258.967 109.643 271.538 101.273 277.273"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.151 312.188C102.151 312.188 109.866 309.04 110.746 304.035"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7467 256.563C92.7467 256.563 87.9368 256.597 83.2952 253.762"
        stroke="white"
        strokeWidth="2.89797"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.746 350.211C212.746 350.211 145.414 378.088 100.01 388.532"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.16 258.869C190.32 257.871 194.048 258.861 194.048 258.861L187.42 324.931L212.75 350.22L214.589 359.523C162.447 384.124 98.2261 400.27 98.2261 400.27L94.4925 389.475L109.548 353.412"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.418 324.931L108.043 349.817L60.2338 305.151C61.7967 300.906 64.7894 296.474 69.0745 294.143"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.937 355.272L119.849 374.271"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.407 349.256L131.884 371.138"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.66 345.709L144.519 366.767"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.722 337.734L171.56 350.473"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.161 335.509L180.537 345.187"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.163 362.542C114.163 362.542 170.702 342.79 186.304 335.086"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.497 369.864C115.497 369.864 126.315 367.661 155.823 355.916"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0298 257.102L51.5852 258.296"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3735 222.574L65.286 229.211"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.4985 197.562L90.2434 208.125"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.551 185.31L121.583 198.982"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.143 189.298L153.772 203.706"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.559 206.424L176.583 217.329"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M546.732 154.182C546.732 154.182 565.186 183.122 578.692 196.596C578.692 196.596 542.06 220.496 531.632 237.913C531.632 237.913 510.262 211.773 488.76 199.086C488.76 199.086 509.14 171.686 546.732 154.182Z"
        fill="white"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M600.274 150.657L574.952 145.212L572.617 164.123L577.815 168.37L579.215 171.161L583.958 174.923L589.475 174.159L592.124 169.851L599.244 168.041L600.274 150.657Z"
        fill="white"
      />
      <path
        d="M571.588 140.289C583.587 152.712 603.383 153.069 615.803 141.085C628.224 129.101 628.565 109.316 616.566 96.8927C604.567 84.4696 584.771 84.1133 572.351 96.097C559.931 108.081 559.589 127.866 571.588 140.289Z"
        fill="#F9CA20"
      />
      <path
        d="M581.936 147.385C581.936 147.385 578.33 121.608 579.642 112.356C579.903 110.544 582.482 103.514 584.295 103.803C587.34 104.273 586.517 114.175 589.589 114.934C591.673 115.438 595.148 107.709 597.823 107.731C600.319 107.751 598.668 116.613 601.153 116.869C603.549 117.123 605.539 108.651 607.878 109.249C610.612 109.945 611.741 118.691 610.996 121.409C608.187 131.742 597.13 149.692 597.13 149.692"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M599.943 156.788L579.763 153.164M579.733 160.737L599.269 164.146M599.269 164.146L598.876 168.439L591.85 169.823C591.361 171.845 589.115 175.919 584.356 174.961C579.453 173.974 578.807 169.111 578.765 167.681L572.738 164.719L575.266 145.263L600.56 150.043L599.269 164.146Z"
        stroke="#311744"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UploadDesignFront = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg width="438" height="468" viewBox="0 0 438 468" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M315.51 421.8L333.992 365.039L389.121 344.393L396.091 341.783L403.218 343.447L408.362 349.466L393.126 407.178L391.702 403.377L382.756 398.782L375.786 401.393L315.51 421.8Z"
        fill="#7390F6"
      />
      <path
        d="M314.716 420.65L373.51 400.879L389.423 345.273L333.128 364.956L314.716 420.65Z"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M389.536 345.111C402.428 337.193 407.138 349.771 407.138 349.771L393.147 407.318L384.331 433.393L378.363 449.686L375.231 449.314L376.5 442.93"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M373.56 400.916C373.56 400.916 386.191 394.488 393.284 407.396"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M372.753 406.647L333.348 426.467"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M376.45 412.013L355.818 432.396"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M382.969 415.9L366.08 442.897"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M376.511 360.362L340.436 368.126L335.295 380.977L374.814 372.97L376.511 360.362Z" fill="#311744" />
      <path
        d="M18.737 53.6422L47.9617 13.0445"
        stroke="#733E9D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.0396 18.7212L53.6542 47.9517"
        stroke="#733E9D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.66656 37.3565L58.0399 29.3197"
        stroke="#733E9D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.3791 58.0207L29.3169 8.65039"
        stroke="#733E9D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const GoogleDriveIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 33 32" width="33" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.4636 23.9306C24.1807 24.4223 24.306 24.8188 23.8318 25.0921C23.3576 25.366 22.8114 25.5167 22.2431 25.5167H11.2569C10.6891 25.5167 10.1435 25.366 9.66869 25.0927C9.19393 24.8193 9.31923 24.4223 9.03638 23.9306L13.0914 19.1724H20.4085L24.4636 23.9306Z"
        fill="#1E88E5"
      />
      <path
        d="M18.5805 7.01144C19.1478 7.01038 19.6929 6.63289 20.1671 6.90675C20.6414 7.18115 21.0448 7.57819 21.3287 8.0704L26.8218 17.5847C27.1057 18.0764 27.2484 18.6247 27.249 19.1719C27.2495 19.7201 26.5791 20.2678 26.2941 20.7585L20.4114 19.1698L16.7507 12.8292L18.5805 7.01144Z"
        fill="#FBC02D"
      />
      <path
        d="M27.2497 19.1724C27.2497 19.7222 27.107 20.2668 26.8215 20.7584L24.8812 24.1156C24.601 24.528 24.2415 24.8558 23.8291 25.0937L20.4084 19.1724H27.2497Z"
        fill="#E53935"
      />
      <path
        d="M7.20555 20.7588C6.92111 20.2682 6.25073 19.7204 6.25073 19.1727C6.25126 18.625 6.39348 18.0767 6.67792 17.5845L12.171 8.0702C12.4549 7.57851 12.8583 7.18094 13.332 6.90707C13.8063 6.63268 14.3519 7.01017 14.9192 7.01123L16.7479 12.8316L13.0883 19.1701L7.20555 20.7588Z"
        fill="#4CAF50"
      />
      <path
        d="M13.0913 19.1724L9.67063 25.0937C9.25825 24.8558 8.89874 24.528 8.61854 24.1156L6.67824 20.7584C6.39275 20.2668 6.25 19.7222 6.25 19.1724H13.0913Z"
        fill="#1565C0"
      />
      <path
        d="M20.1653 6.90635L16.75 12.8277L13.3293 6.90635C13.7417 6.66844 14.207 6.52041 14.7039 6.48869L18.5793 6.4834C19.1502 6.4834 19.6948 6.63143 20.1653 6.90635Z"
        fill="#2E7D32"
      />
    </svg>
  );
};

const OneDriveIcon = () => {
  return (
    <svg fill="none" height="32" viewBox="0 0 33 32" width="33" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6648_95220)">
        <path
          d="M13.1309 12.8195L17.8937 15.818L20.7317 14.5621C21.3085 14.3001 21.9303 14.1654 22.5587 14.1663C22.6636 14.1663 22.7671 14.1715 22.8699 14.1782C22.5288 12.7794 21.8086 11.5149 20.7967 10.5384C19.7849 9.56187 18.525 8.91538 17.1702 8.67751C15.8154 8.43964 14.4241 8.62063 13.1653 9.19849C11.9065 9.77636 10.8345 10.7262 10.0796 11.9326L10.1519 11.9303C11.2044 11.9289 12.2362 12.2369 13.1309 12.8195Z"
          fill="#0364B8"
        />
        <path
          d="M13.1316 12.8191C12.2366 12.2366 11.2045 11.9289 10.1519 11.9307L10.0796 11.9329C9.04947 11.9465 8.04233 12.2548 7.16639 12.8249C6.29044 13.3949 5.57879 14.2051 5.1079 15.1684C4.63701 16.1317 4.42467 17.2116 4.4937 18.2922C4.56273 19.3729 4.91053 20.4133 5.49971 21.3017L9.69816 19.4436L11.5656 18.6178L15.7222 16.7783L17.893 15.8183L13.1316 12.8191Z"
          fill="#0078D4"
        />
        <path
          d="M22.8697 14.1784C22.7661 14.1708 22.6623 14.1668 22.5585 14.1665C21.9304 14.166 21.3088 14.3009 20.7322 14.563L17.8936 15.8182L18.7167 16.3362L21.4142 18.0348L22.5911 18.7756L26.6159 21.3097C26.9816 20.5961 27.1711 19.7974 27.1671 18.9868C27.163 18.1762 26.9656 17.3796 26.5928 16.67C26.2201 15.9604 25.6839 15.3604 25.0335 14.9251C24.383 14.4899 23.639 14.2331 22.8697 14.1784Z"
          fill="#1490DF"
        />
        <path
          d="M22.5913 18.7753L21.4144 18.0345L18.7168 16.3351L17.8944 15.8179L15.7229 16.7779L11.5663 18.6173L9.69816 19.4432L5.49829 21.3013C6.02031 22.0902 6.71623 22.7346 7.52661 23.1794C8.33698 23.6242 9.23772 23.8562 10.1519 23.8555H22.5587C23.3906 23.8558 24.2071 23.6193 24.9215 23.171C25.6359 22.7227 26.2214 22.0795 26.616 21.3095L22.5913 18.7753Z"
          fill="#28A8EA"
        />
      </g>
      <defs>
        <clipPath id="clip0_6648_95220">
          <rect fill="white" height="32" transform="translate(0.25)" width="32" />
        </clipPath>
      </defs>
    </svg>
  );
};

const UploadDesignTablet = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg width="283" height="407" viewBox="0 0 283 407" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M64.9782 145.208C65.7391 145.535 66.6215 145.184 66.949 144.423C67.2766 143.662 66.9252 142.78 66.1643 142.452L64.9782 145.208ZM31.2896 177.285L30.6509 178.643L31.2896 177.285ZM45.9641 157.818L44.9932 158.962L45.9641 157.818ZM1.17753 176.164C1.22905 176.99 1.94108 177.619 2.7679 177.567L16.2418 176.728C17.0686 176.676 17.6971 175.964 17.6456 175.138C17.5941 174.311 16.882 173.682 16.0552 173.734L4.07845 174.48L3.33224 162.503C3.28073 161.676 2.5687 161.048 1.74187 161.099C0.915048 161.151 0.286536 161.863 0.338051 162.69L1.17753 176.164ZM66.1643 142.452C50.8955 135.88 38.0947 143.031 31.0829 152.504C27.586 157.229 25.4338 162.638 25.1181 167.401C24.8027 172.159 26.3405 176.614 30.6509 178.643L31.9283 175.928C29.1664 174.629 27.8408 171.683 28.1115 167.6C28.3818 163.523 30.2652 158.652 33.4942 154.289C39.9342 145.589 51.3465 139.34 64.9782 145.208L66.1643 142.452ZM30.6509 178.643C37.9583 182.082 45.5913 179.788 49.6376 174.999C51.6709 172.592 52.8225 169.521 52.5033 166.235C52.1833 162.941 50.405 159.621 46.9349 156.675L44.9932 158.962C47.9938 161.509 49.2872 164.156 49.5173 166.525C49.7482 168.902 48.9283 171.19 47.3459 173.063C44.1609 176.833 37.9858 178.779 31.9283 175.928L30.6509 178.643ZM46.9349 156.675C42.4056 152.829 37.9127 151.176 33.5057 151.208C29.1314 151.241 25.0309 152.934 21.2544 155.428C13.7761 160.368 7.07807 168.815 1.55006 175.078L3.79919 177.063C9.52504 170.576 15.8604 162.586 22.9078 157.932C26.3941 155.629 29.9295 154.235 33.5281 154.208C37.094 154.182 40.9112 155.496 44.9932 158.962L46.9349 156.675Z"
        fill="#CFB6E2"
      />
      <path
        d="M186.819 216.524C186.819 216.524 143.85 247.17 124.237 268.984C124.237 268.984 84.6153 214.638 57.0384 200.014C57.0384 200.014 95.4752 165.114 113.241 131.084C113.241 131.084 156.942 160.194 186.819 216.524Z"
        fill="white"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M56.9091 101.318C56.9091 101.318 117.289 59.4334 143.093 28.6741C143.093 28.6741 217.319 112.929 263.771 137.059C263.771 137.059 211.439 185.662 191.269 234.751C191.269 234.751 116.325 187.684 56.9091 101.318Z"
        fill="#FB7268"
      />
      <path
        d="M88.7378 119.489C110.325 99.5756 131.223 82.5732 149.263 59.8049"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.369 131.185C121.956 111.272 142.854 94.2693 160.894 71.501"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.998 142.881C133.585 122.967 154.483 105.965 172.523 83.1968"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.626 154.577C145.214 134.664 166.112 117.661 184.152 94.8931"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.255 166.274C156.843 146.36 177.741 129.358 195.781 106.589"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.884 177.97C168.472 158.056 189.369 141.054 207.41 118.285"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.513 189.666C180.101 169.752 200.998 152.75 219.039 129.982"
        stroke="#311744"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M207.162 226.07L278.738 187.674L357.742 169.665L270.805 232.634L207.162 226.07Z" fill="#37C78B" />
      <path
        d="M332.076 265.401L335.498 272.639L250.812 326.263C250.812 326.263 196.69 271.192 195.601 250.646C194.512 230.1 207.15 226.089 207.15 226.089L270.797 232.653L357.743 169.663L278.733 187.675L215.373 221.185"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.893 251.497L251.539 306.885L339.297 260.83L325.292 251.458"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.272 242.915L254.621 289.535L335.746 245.357L327.456 238.493"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.425 238.824L258.902 260.69L342.327 231.571L294.87 215.226"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.621 233.506C236.719 234.339 237.819 235.16 238.93 235.996C240.041 236.831 249.57 239.19 248.795 240.332C250.302 240.449 255.425 240.849 256.192 241.52C257.831 242.976 256.769 245.645 255.624 247.495C258.589 248.084 261.542 248.672 264.507 249.261"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M269.704 238.364C269.25 238.883 268.799 239.39 268.333 239.908C278.281 238.103 279.583 242.934 279.165 244.229C281.064 244.797 282.973 245.38 284.872 245.949"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M283.012 231.072C289.022 230.869 292.939 229.227 298.392 226.688C297.132 228.656 297.131 233.262 295.871 235.229C297.369 235.191 302.535 233.678 305.019 233.445C304.099 234.495 302.87 238.151 301.962 239.203"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M97.3708 400.1L94.1801 390.875L109.147 348.514L186.333 325.95L212.185 350.061L215.376 359.287L200.771 367.695L142.573 387.823L97.3708 400.1Z"
        fill="white"
      />
      <path
        d="M110.858 221.024C105.638 217.339 98.2294 215.759 91.3193 218.149C91.2407 218.176 91.1635 218.208 91.088 218.243C81.775 222.552 74.8724 231.528 75.3567 242.607C75.36 242.684 75.367 242.761 75.3776 242.838C75.8939 246.58 77.5408 250.519 80.0529 253.618C75.8584 255.166 72.7252 258.154 70.8719 261.839C68.3861 266.781 68.2394 272.887 70.6858 278.322C70.6971 278.347 70.7089 278.372 70.7211 278.397C73.4004 283.854 78.1939 288.718 84.1515 291.053C83.0523 292.252 82.202 293.602 81.4865 294.841C81.4446 294.914 81.4063 294.988 81.3719 295.065C79.7429 298.692 80.0736 302.939 82.2644 307.333C84.1506 311.122 87.379 314.1 91.4766 315.422C94.8194 316.501 98.5929 316.431 102.481 314.984C104.787 320.591 110.273 323.952 115.836 324.928C122.313 326.063 129.819 324.129 133.67 317.834L133.671 317.832C134.806 315.973 135.582 313.837 135.937 311.473C136.967 312.398 138.036 313.112 139.118 313.637C142.381 315.245 145.454 314.832 147.405 313.908L147.411 313.905C151.385 312.011 153.12 308.502 153.173 304.634C153.206 302.148 152.72 299.72 151.767 297.398C152.602 297.208 153.564 296.983 154.606 296.731C156.879 296.181 159.487 295.39 161.985 294.633C163.264 294.245 164.514 293.866 165.676 293.533C169.304 292.491 172.21 291.263 173.935 288.579C175.568 286.037 175.812 282.646 175.828 278.432L175.828 278.432C175.834 276.628 175.857 275.083 175.879 273.579C175.967 267.473 176.046 262.058 175.015 242.877L175.015 242.877C174.619 235.518 171.478 228.96 166.797 226.127C166.796 226.127 166.795 226.126 166.794 226.126C163.906 224.375 160.517 223.705 157.232 223.59C153.932 223.474 150.572 223.912 147.604 224.522C144.626 225.133 141.973 225.932 140.067 226.575C139.928 226.622 139.793 226.669 139.662 226.714C137.461 219.406 131.909 213.484 123.405 213.242C123.244 213.238 123.082 213.249 122.923 213.276C117.53 214.182 113.556 217.007 110.858 221.024Z"
        fill="#733E9D"
        stroke="#311744"
        strokeWidth="4.96795"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.958 230.018C136.601 222.217 131.449 215.999 123.375 215.792C117.705 216.753 113.856 220.084 111.518 224.893C106.984 220.461 99.1852 218.159 92.0376 220.631C83.4119 224.614 77.2243 232.754 77.6329 242.612C78.284 247.387 81.0309 252.437 84.9469 255.082C73.2987 256.111 68.3466 267.824 72.6381 277.339C76.0028 284.175 82.9413 289.775 91.0156 289.983C87.1329 290.326 85.0704 293.039 83.319 296.044C82.0584 298.82 82.1983 302.262 84.1461 306.158C87.3721 312.622 95.0848 316.065 103.772 311.42C105.976 323.547 124.994 326.649 131.367 316.325C133.487 312.882 134.251 308.118 132.694 302.347C134.819 307.332 137.542 309.97 140.073 311.185C142.601 312.42 144.887 312.059 146.228 311.426C149.235 309.996 150.56 307.437 150.61 304.37C150.66 301.303 149.78 298.297 148.038 295.46C148.038 295.46 150.586 294.929 153.982 294.104C157.378 293.28 161.399 291.949 165.002 290.913C172.208 288.84 173.366 286.78 173.42 278.201C173.477 269.082 173.922 266.638 172.698 242.882C172.343 236.015 169.417 230.417 165.682 228.179C156.06 222.397 137.958 230.018 137.958 230.018Z"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.742 302.57C126.445 276.728 119.274 250.209 111.993 224.878"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.816 257.96C139.684 253.059 151.95 249.017 161.921 246.578"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.056 269.061C143.858 264.837 153.748 261.326 163.304 258.731"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.06 280.73C138.06 280.73 146.656 277.007 155.715 274.624"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.059 288.472C114.417 288.921 120.462 294.83 122.047 303.002"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.519 251.072C111.3 258.967 109.647 271.538 101.277 277.273"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.155 312.188C102.155 312.188 109.869 309.04 110.749 304.035"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.7504 256.563C92.7504 256.563 87.9404 256.597 83.2988 253.762"
        stroke="white"
        strokeWidth="2.89797"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212.75 350.211C212.75 350.211 145.418 378.088 100.014 388.532"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.163 258.869C190.324 257.871 194.051 258.861 194.051 258.861L187.423 324.931L212.753 350.22L214.592 359.523C162.451 384.124 98.2296 400.27 98.2296 400.27L94.4959 389.475L109.552 353.412"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.421 324.931L108.047 349.817L60.2372 305.151C61.8001 300.906 64.7929 296.474 69.0779 294.143"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.941 355.272L119.853 374.271"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.41 349.256L131.887 371.138"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.663 345.709L144.522 366.767"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.726 337.734L171.563 350.473"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.164 335.509L180.541 345.187"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.166 362.542C114.166 362.542 170.706 342.79 186.307 335.086"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.5 369.864C115.5 369.864 126.319 367.661 155.826 355.916"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0332 257.102L51.5886 258.296"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.377 222.574L65.2894 229.211"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.502 197.562L90.2468 208.125"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.555 185.31L121.586 198.982"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.147 189.298L153.776 203.706"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183.563 206.424L176.586 217.329"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M250.731 122.182C250.731 122.182 269.185 151.122 282.691 164.596C282.691 164.596 246.059 188.496 235.631 205.913C235.631 205.913 214.261 179.772 192.759 167.086C192.759 167.086 213.139 139.686 250.731 122.182Z"
        fill="white"
        stroke="#311744"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M304.274 118.656L278.951 113.212L276.616 132.123L281.814 136.37L283.215 139.161L287.958 142.923L293.474 142.159L296.124 137.851L303.243 136.04L304.274 118.656Z"
        fill="white"
      />
      <path
        d="M275.588 108.289C287.587 120.712 307.383 121.068 319.803 109.085C332.223 97.1011 332.565 77.3156 320.566 64.8925C308.567 52.4694 288.771 52.1131 276.351 64.0967C263.93 76.0804 263.589 95.866 275.588 108.289Z"
        fill="#F9CA20"
      />
      <path
        d="M285.936 115.384C285.936 115.384 282.329 89.6081 283.641 80.3554C283.903 78.5433 286.482 71.5141 288.295 71.8026C291.34 72.2732 290.516 82.1745 293.588 82.9335C295.673 83.438 299.147 75.7084 301.822 75.731C304.318 75.7503 302.668 84.6131 305.153 84.8689C307.549 85.1232 309.539 76.6504 311.877 77.249C314.611 77.9444 315.74 86.6908 314.996 89.4091C312.187 99.7416 301.13 117.692 301.13 117.692"
        stroke="white"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M303.942 124.788L283.763 121.164M283.732 128.737L303.268 132.146M303.268 132.146L302.875 136.438L295.849 137.823C295.36 139.845 293.114 143.918 288.355 142.961C283.453 141.974 282.807 137.11 282.765 135.681L276.738 132.719L279.265 113.262L304.56 118.043L303.268 132.146Z"
        stroke="#311744"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const UploadDesignMobile = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg width="358" height="244" viewBox="0 0 358 244" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M123.863 22.0259C124.498 22.2989 125.233 22.0061 125.506 21.372C125.779 20.7379 125.486 20.0026 124.852 19.7296L123.863 22.0259ZM95.855 48.6936L95.3228 49.8246L95.855 48.6936ZM108.056 32.5079L107.247 33.4608L108.056 32.5079ZM70.8162 47.761C70.8592 48.45 71.4525 48.9738 72.1415 48.9308L83.3698 48.2313C84.0588 48.1883 84.5825 47.595 84.5396 46.906C84.4967 46.2169 83.9033 45.6932 83.2143 45.7361L73.2337 46.358L72.6118 36.3773C72.5689 35.6883 71.9755 35.1645 71.2865 35.2075C70.5975 35.2504 70.0737 35.8437 70.1167 36.5328L70.8162 47.761ZM124.852 19.7296C112.155 14.2647 101.511 20.2113 95.6809 28.0882C92.7732 32.0164 90.9836 36.5142 90.721 40.4755C90.4587 44.4315 91.7375 48.1374 95.3228 49.8246L96.3873 47.5625C94.0925 46.4826 92.9905 44.0351 93.2155 40.6409C93.4402 37.2518 95.0058 33.2023 97.6903 29.5755C103.044 22.3424 112.531 17.1482 123.863 22.0259L124.852 19.7296ZM95.3228 49.8246C101.4 52.6843 107.747 50.777 111.112 46.7941C112.803 44.7926 113.761 42.2382 113.495 39.5053C113.229 36.7658 111.75 34.0049 108.865 31.555L107.247 33.4608C109.741 35.5785 110.816 37.7783 111.007 39.7471C111.199 41.7225 110.518 43.624 109.202 45.1807C106.555 48.3141 101.422 49.9321 96.3873 47.5625L95.3228 49.8246ZM108.865 31.555C105.099 28.3574 101.362 26.9823 97.6976 27.0096C94.0598 27.0367 90.6501 28.4444 87.5099 30.5185C81.2919 34.6256 75.7226 41.6494 71.1267 46.8561L73.0009 48.5105C77.7618 43.117 83.0288 36.4745 88.8878 32.6045C91.7861 30.6902 94.7249 29.5318 97.7162 29.5095C100.68 29.4874 103.853 30.5796 107.247 33.4608L108.865 31.555Z"
        fill="#C5B0E8"
      />
      <path
        d="M269.536 113.484C269.536 113.484 225.752 116.386 202.346 123.246C202.346 123.246 198.068 67.4909 184.952 45.0968C184.952 45.0968 227.39 37.2058 254.826 20.8987C254.826 20.8987 273.017 60.585 269.536 113.484Z"
        fill="white"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M106.033 84.2381C106.033 84.2381 156.235 49.4143 177.689 23.8402C177.689 23.8402 239.402 93.8922 278.023 113.954C278.023 113.954 234.513 154.364 217.744 195.178C217.744 195.178 155.433 156.045 106.033 84.2381Z"
        fill="#6AC3A5"
      />
      <path
        d="M132.496 99.3461C150.445 82.7895 167.819 68.6533 182.818 49.7232"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.168 109.07C160.117 92.5139 177.491 78.3777 192.49 59.4475"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.836 118.795C169.785 102.238 187.159 88.1018 202.158 69.1717"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.504 128.519C179.453 111.963 196.827 97.8264 211.826 78.8963"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.172 138.244C189.12 121.687 206.495 107.551 221.494 88.6209"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.84 147.968C198.788 131.412 216.163 117.275 231.162 98.3452"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.512 157.693C208.46 141.136 225.835 127 240.834 108.07"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M228.035 152.29L287.545 120.366L353.231 105.392L280.949 157.747L228.035 152.29Z" fill="#DC5579" />
      <path
        d="M331.89 184.99L334.736 191.008L264.325 235.592C264.325 235.592 219.327 189.805 218.422 172.723C217.516 155.64 228.024 152.305 228.024 152.305L280.942 157.762L353.23 105.391L287.539 120.367L234.86 148.228"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.315 173.43L264.93 219.481L337.894 181.19L326.249 173.398"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.79 166.294L267.494 205.056L334.943 168.325L328.051 162.618"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.567 162.893L271.052 181.073L340.414 156.863L300.957 143.273"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M251.695 158.471C252.608 159.165 253.523 159.847 254.446 160.542C255.37 161.236 263.293 163.198 262.648 164.147C263.902 164.245 268.161 164.577 268.799 165.135C270.161 166.345 269.278 168.564 268.326 170.103C270.792 170.593 273.246 171.081 275.712 171.571"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M280.035 162.511C279.658 162.942 279.283 163.364 278.895 163.794C287.166 162.294 288.249 166.31 287.902 167.387C289.48 167.86 291.068 168.345 292.646 168.818"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M291.1 156.448C296.096 156.279 299.354 154.914 303.887 152.804C302.84 154.439 302.839 158.269 301.791 159.905C303.036 159.872 307.332 158.615 309.397 158.421C308.632 159.294 307.61 162.334 306.855 163.208"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="bevel"
      />
      <path
        d="M61.5079 238.719L58.855 231.049L71.2987 195.829L135.473 177.068L156.968 197.115L159.62 204.785L147.477 211.776L99.0906 228.511L61.5079 238.719Z"
        fill="white"
      />
      <path
        d="M96.6261 94.6447C94.8159 88.5581 90.2105 83.6257 83.151 83.4253C83.021 83.4217 82.891 83.4307 82.7627 83.4522C78.2738 84.2069 74.9722 86.5663 72.7376 89.9223C68.4107 86.8422 62.2452 85.5136 56.4971 87.5017C56.4338 87.5236 56.3716 87.5486 56.3108 87.5768C48.587 91.1513 42.8679 98.5922 43.2692 107.772C43.2719 107.834 43.2775 107.896 43.286 107.958C43.7179 111.088 45.1043 114.382 47.2171 116.96C43.7021 118.232 41.0797 120.72 39.5335 123.794C37.4759 127.885 37.3538 132.941 39.38 137.442C39.3892 137.462 39.3986 137.483 39.4085 137.503C41.6436 142.055 45.652 146.107 50.6293 148.028C49.6891 149.036 48.9655 150.182 48.3571 151.236C48.3233 151.295 48.2925 151.355 48.2648 151.416C46.9205 154.409 47.1904 157.919 49.005 161.559C50.5661 164.696 53.2375 167.16 56.6265 168.253C59.4075 169.151 62.5513 169.088 65.7921 167.868C67.6856 172.539 72.2419 175.342 76.8706 176.154C82.238 177.095 88.4478 175.491 91.6315 170.287L91.6326 170.285C92.5899 168.716 93.2397 166.907 93.5251 164.901C94.4055 165.707 95.3204 166.323 96.2454 166.772C98.939 168.1 101.473 167.758 103.079 166.997L103.083 166.995C106.362 165.432 107.794 162.54 107.837 159.345C107.865 157.262 107.452 155.228 106.644 153.283C107.355 153.121 108.181 152.929 109.078 152.712C110.967 152.255 113.132 151.598 115.208 150.969C116.272 150.646 117.312 150.331 118.28 150.053C121.295 149.187 123.69 148.171 125.109 145.962C126.456 143.867 126.66 141.066 126.673 137.56L126.673 137.56C126.678 136.06 126.697 134.775 126.715 133.525C126.789 128.449 126.854 123.948 125.998 108.003L125.998 108.002C125.669 101.897 123.063 96.4687 119.196 94.129C116.807 92.6802 114.001 92.1246 111.275 92.0289C108.538 91.9329 105.75 92.2961 103.285 92.8022C100.813 93.3099 98.6095 93.973 97.0269 94.5076C96.8886 94.5543 96.755 94.6 96.6261 94.6447Z"
        fill="#572E9E"
        stroke="#160B28"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.2539 97.3078C94.1253 90.8224 89.8421 85.6526 83.1289 85.4802C78.415 86.2792 75.2144 89.0489 73.2704 93.0469C69.5009 89.3623 63.0168 87.4478 57.0741 89.5032C49.9025 92.815 44.7579 99.5828 45.0977 107.779C45.639 111.749 47.9229 115.948 51.1787 118.147C41.4941 119.003 37.3768 128.741 40.9448 136.652C43.7423 142.336 49.5112 146.992 56.2244 147.164C52.9962 147.449 51.2814 149.705 49.8252 152.204C48.7771 154.512 48.8935 157.373 50.5129 160.613C53.1951 165.987 59.6076 168.849 66.8303 164.988C68.6626 175.071 84.4753 177.65 89.7737 169.066C91.5365 166.203 92.171 162.243 90.8772 157.444C92.6434 161.589 94.9075 163.782 97.0116 164.792C99.1135 165.819 101.014 165.519 102.129 164.992C104.629 163.804 105.731 161.676 105.773 159.126C105.814 156.576 105.083 154.077 103.635 151.718C103.635 151.718 105.752 151.276 108.576 150.591C111.4 149.905 114.743 148.799 117.738 147.937C123.73 146.214 124.692 144.501 124.737 137.369C124.785 129.787 125.155 127.755 124.137 108.003C123.842 102.294 121.409 97.6398 118.304 95.779C110.304 90.9719 95.2539 97.3078 95.2539 97.3078Z"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.9184 157.629C85.6831 136.144 79.7206 114.095 73.6673 93.0343"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.1473 120.54C96.689 116.465 106.888 113.104 115.177 111.077"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.8414 129.769C100.16 126.257 108.382 123.339 116.327 121.181"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.3395 139.471C95.3395 139.471 102.486 136.376 110.018 134.395"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.7305 145.908C75.6796 146.282 80.7056 151.195 82.0241 157.988"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.4494 114.813C73.0879 121.377 71.7128 131.829 64.7538 136.597"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.4866 165.626C65.4866 165.626 71.9004 163.009 72.6321 158.848"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.6669 119.379C57.6669 119.379 53.6677 119.407 49.8086 117.05"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.439 197.24C157.439 197.24 101.457 220.418 63.707 229.1"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.67 121.296C138.792 120.466 141.891 121.289 141.891 121.289L136.38 176.222L157.44 197.247L158.969 204.982C115.618 225.435 62.2225 238.86 62.2225 238.86L59.1183 229.885L71.6359 199.901"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.38 176.221L70.3856 196.912L30.6357 159.776C31.9351 156.246 34.4234 152.561 37.9861 150.623"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.9249 201.447L80.2002 217.244"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.9667 196.446L90.2064 214.638"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.494 193.497L100.714 211.004"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.669 186.865L123.197 197.457"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.02 185.016L130.658 193.062"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.4754 207.492C75.4754 207.492 122.484 191.07 135.455 184.664"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.582 213.579C76.582 213.579 85.5769 211.748 110.11 201.983"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3438 119.826L23.4455 120.819"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9297 91.1194L34.834 96.6376"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.3164 70.323L55.5871 79.1057"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.1196 60.1369L81.6401 71.5039"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.375 63.4525L108.404 75.4316"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.169 77.6916L127.369 86.7586"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.434 142.862C235.434 142.862 250.777 166.923 262.006 178.126C262.006 178.126 231.549 197.997 222.88 212.478C222.88 212.478 205.112 190.744 187.235 180.196C187.235 180.196 204.179 157.415 235.434 142.862Z"
        fill="white"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinejoin="bevel"
      />
      <path
        d="M315.338 81.4262L294.284 76.8994L292.342 92.6224L296.664 96.1536L297.829 98.4742L301.772 101.602L306.359 100.967L308.562 97.3849L314.481 95.8797L315.338 81.4262Z"
        fill="white"
      />
      <path
        d="M291.484 72.8068C301.46 83.1357 317.919 83.4319 328.246 73.4684C338.572 63.5049 338.856 47.0547 328.88 36.7258C318.904 26.3969 302.445 26.1007 292.118 36.0642C281.792 46.0277 281.508 62.478 291.484 72.8068Z"
        fill="#F4B948"
      />
      <path
        d="M300.088 78.706C300.088 78.706 297.09 57.275 298.18 49.5821C298.398 48.0754 300.542 42.2312 302.05 42.471C304.581 42.8623 303.896 51.0945 306.451 51.7255C308.184 52.145 311.072 45.7185 313.297 45.7372C315.372 45.7533 313.999 53.122 316.066 53.3347C318.058 53.5461 319.712 46.5016 321.657 46.9993C323.93 47.5775 324.868 54.8495 324.249 57.1095C321.914 65.7003 312.721 80.6248 312.721 80.6248"
        stroke="white"
        strokeWidth="2.5"
        strokeMiterlimit="10"
      />
      <path
        d="M315.061 86.5243L298.283 83.5112M298.258 89.8077L314.5 92.6421M314.5 92.6421L314.174 96.2108L308.332 97.3617C307.925 99.0428 306.058 102.43 302.101 101.634C298.025 100.813 297.488 96.7695 297.453 95.5812L292.442 93.1183L294.544 76.9416L315.574 80.9163L314.5 92.6421Z"
        stroke="#160B28"
        strokeWidth="2.5"
        strokeLinejoin="round"
      />
      <path
        d="M174.941 229.054L195.128 201.01"
        stroke="#572E9E"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M171.008 204.932L199.063 225.123"
        stroke="#572E9E"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M167.986 217.804L202.091 212.253"
        stroke="#572E9E"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M187.819 232.078L182.25 197.975"
        stroke="#572E9E"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

const HeroGradient = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg width="391" height="382" viewBox="0 0 391 382" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.15" filter="url(#filter0_f_156_1895)">
        <ellipse
          cx="218.17"
          cy="173.876"
          rx="218.17"
          ry="173.876"
          transform="matrix(0.995494 -0.09482 -0.262858 0.964835 58.4551 248.374)"
          fill="#8648B7"
        />
      </g>
      <g opacity="0.15" filter="url(#filter1_f_156_1895)">
        <ellipse
          cx="227.736"
          cy="173.876"
          rx="227.736"
          ry="173.876"
          transform="matrix(0.916351 -0.400375 0.0509584 0.998701 -235 -103.64)"
          fill="#CFB6E2"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_156_1895"
          x="-192.051"
          y="26.3987"
          width="843.979"
          height="738.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_156_1895" />
        </filter>
        <filter
          id="filter1_f_156_1895"
          x="-426.332"
          y="-417.343"
          width="817.758"
          height="792.346"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_156_1895" />
        </filter>
      </defs>
    </svg>
  );
};

const HeroErrorGradient = (props: SVGProps<ElementRef<"svg">>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="391" height="382" viewBox="0 0 391 382" fill="none" {...props}>
      <g opacity="0.15" filter="url(#filter0_f_208_6446)">
        <ellipse
          cx="218.17"
          cy="173.876"
          rx="218.17"
          ry="173.876"
          transform="matrix(0.995494 -0.09482 -0.262858 0.964835 58.4551 248.374)"
          fill="#C80E20"
        />
      </g>
      <g opacity="0.15" filter="url(#filter1_f_208_6446)">
        <ellipse
          cx="227.736"
          cy="173.876"
          rx="227.736"
          ry="173.876"
          transform="matrix(0.916351 -0.400375 0.0509584 0.998701 -235 -103.64)"
          fill="#C80E20"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_208_6446"
          x="-192.051"
          y="26.3987"
          width="843.979"
          height="738.1"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_208_6446" />
        </filter>
        <filter
          id="filter1_f_208_6446"
          x="-426.332"
          y="-417.343"
          width="817.758"
          height="792.346"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_208_6446" />
        </filter>
      </defs>
    </svg>
  );
};

const ExclamationErrorMarks = (props: SVGProps<ElementRef<"svg">>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="131" height="101" viewBox="0 0 131 101" fill="none" {...props}>
    <path
      d="M31.8491 91.6753C36.1464 91.2461 39.3316 87.9088 38.9633 84.2212C38.5951 80.5336 34.8128 77.8921 30.5154 78.3213C26.2181 78.7505 23.0329 82.0878 23.4012 85.7754C23.7695 89.463 27.5517 92.1045 31.8491 91.6753Z"
      fill="white"
    />
    <path
      d="M31.8491 91.6753C36.1464 91.2461 39.3316 87.9088 38.9633 84.2212C38.5951 80.5336 34.8128 77.8921 30.5154 78.3213C26.2181 78.7505 23.0329 82.0878 23.4012 85.7754C23.7695 89.463 27.5517 92.1045 31.8491 91.6753Z"
      fill="#C80E20"
    />
    <path
      d="M31.3939 73.079L24.0079 74.6226C17.4042 62.1767 12.027 42.1552 10.1638 33.7002L27.6412 31.5543C26.9037 39.0465 29.8357 62.3592 31.3939 73.079Z"
      fill="white"
    />
    <path
      d="M31.3939 73.079L24.0079 74.6226C17.4042 62.1767 12.027 42.1552 10.1638 33.7002L27.6412 31.5543C26.9037 39.0465 29.8357 62.3592 31.3939 73.079Z"
      fill="#C80E20"
    />
    <path
      d="M98.4403 90.6839C102.553 92.5735 107.202 91.2443 108.823 87.715C110.445 84.1857 108.425 79.7929 104.312 77.9033C100.199 76.0137 95.5508 77.343 93.9293 80.8722C92.3078 84.4015 94.3275 88.7943 98.4403 90.6839Z"
      fill="white"
    />
    <path
      d="M98.4403 90.6839C102.553 92.5735 107.202 91.2443 108.823 87.715C110.445 84.1857 108.425 79.7929 104.312 77.9033C100.199 76.0137 95.5508 77.343 93.9293 80.8722C92.3078 84.4015 94.3275 88.7943 98.4403 90.6839Z"
      fill="#C80E20"
    />
    <path
      d="M107.884 73.6275L100.388 71.1085C101.014 56.3559 106.764 35.4039 109.56 26.7721L126.499 34.0949C121.862 40.4779 112.157 63.1096 107.884 73.6275Z"
      fill="white"
    />
    <path
      d="M107.884 73.6275L100.388 71.1085C101.014 56.3559 106.764 35.4039 109.56 26.7721L126.499 34.0949C121.862 40.4779 112.157 63.1096 107.884 73.6275Z"
      fill="#C80E20"
    />
    <path
      d="M65.1159 83.7574C70.4063 84.77 75.3994 81.9107 76.2683 77.371C77.1373 72.8313 73.553 68.3303 68.2626 67.3177C62.9723 66.305 57.9792 69.1643 57.1102 73.704C56.2413 78.2437 59.8256 82.7447 65.1159 83.7574Z"
      fill="white"
    />
    <path
      d="M65.1159 83.7574C70.4063 84.77 75.3994 81.9107 76.2683 77.371C77.1373 72.8313 73.553 68.3303 68.2626 67.3177C62.9723 66.305 57.9792 69.1643 57.1102 73.704C56.2413 78.2437 59.8256 82.7447 65.1159 83.7574Z"
      fill="#C80E20"
    />
    <path
      d="M71.172 61.3611L61.7931 60.5843C58.3165 43.3592 58.9959 17.5121 59.7701 6.74172L81.4282 10.3812C77.8865 19.0771 73.115 47.9911 71.172 61.3611Z"
      fill="white"
    />
    <path
      d="M71.172 61.3611L61.7931 60.5843C58.3165 43.3592 58.9959 17.5121 59.7701 6.74172L81.4282 10.3812C77.8865 19.0771 73.115 47.9911 71.172 61.3611Z"
      fill="#C80E20"
    />
  </svg>
);

const LoadingGroup = (props: SVGProps<ElementRef<"svg">>) => (
  <svg width="299" height="112" viewBox="0 0 299 112" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M249.97 35.468C249.97 35.468 272.025 48.2391 277.999 69.7028C277.999 69.7028 238.695 71.9896 220.416 86.5306C220.416 86.5306 208.52 63.4323 181.304 51.542C181.304 51.542 206.314 37.2472 249.97 35.468Z"
      fill="white"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinejoin="bevel"
    />
    <path d="M202.291 95.8492L240.552 62.7359L286.113 41L241.87 91.1472L202.291 95.8492Z" fill="#DC5579" />
    <path
      d="M283.588 102.699L286.657 106.632L242.506 150.79C242.506 150.79 202.088 124.709 198.621 112.369C195.153 100.03 202.286 95.8656 202.286 95.8656L241.869 91.1629L286.114 41.0024L240.55 62.7408L206.615 91.7619"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M203.779 111.752L240.304 138.911L287.355 98.933L277.562 95.1495"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M205.877 105.8L239.808 127.944L283.083 90.0117L277.108 86.972"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M210.271 102.198L238.468 109.824L285.199 80.7323L254.121 77.2784"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M220.604 96.4824C221.385 96.8392 222.165 97.1881 222.955 97.544C223.744 97.8998 229.858 98.0325 229.543 98.8327C230.475 98.6979 233.643 98.2413 234.202 98.5443C235.397 99.2052 235.115 100.973 234.672 102.254C236.555 102.207 238.43 102.161 240.312 102.114"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M241.983 94.778C241.779 95.1555 241.573 95.5252 241.361 95.9036C247.161 93.4483 248.612 96.2064 248.535 97.0506C249.767 97.1371 251.007 97.2306 252.239 97.3172"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M249.077 88.5309C252.702 87.5864 254.859 86.0535 257.826 83.7659C257.329 85.1337 257.958 87.9336 257.46 89.3014C258.366 89.0734 261.299 87.4483 262.777 86.9676C262.361 87.7316 262.113 90.1217 261.705 90.8847"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="bevel"
    />
    <path
      d="M62.2775 71.201C62.2775 71.201 113.673 50.7764 137.827 32.6203C137.827 32.6203 177.896 107.025 207.841 132.623C207.841 132.623 161.202 159.344 138.057 191.897C138.057 191.897 91.1301 144.632 62.2775 71.201Z"
      fill="#627BF1"
    />
    <path
      d="M82.5165 89.9413C101.733 79.0184 119.948 70.1122 137.049 56.4924"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.0497 100.496C108.266 89.5726 126.481 80.6664 143.582 67.0466"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.5829 111.05C114.799 100.127 133.014 91.2208 150.116 77.6011"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102.118 121.604C121.334 110.681 139.549 101.775 156.651 88.1555"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.651 132.159C127.868 121.236 146.082 112.329 163.184 98.7097"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.186 142.714C134.403 131.791 152.617 122.884 169.719 109.265"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M121.718 153.267C140.934 142.344 159.149 133.438 176.25 119.818"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.3563 101.814L56.0473 110.316L63.8006 126.841L70.1818 127.633L72.7412 129.181L78.5058 129.812L82.589 126.379L82.5395 121.534L87.3884 116.434L79.3563 101.814Z"
      fill="white"
    />
    <path
      d="M50.8078 108.034C66.8738 111.986 83.1061 102.176 87.0636 86.1242C91.021 70.072 81.2051 53.8554 65.139 49.9036C49.0729 45.9517 32.8407 55.761 28.8832 71.8132C24.9257 87.8654 34.7417 104.082 50.8078 108.034Z"
      fill="#F4B948"
    />
    <path
      d="M62.82 108.509C62.82 108.509 46.7469 89.4494 43.0906 81.278C42.3784 79.6752 40.884 72.6602 42.501 71.9694C45.21 70.7976 49.5928 79.2459 52.4708 78.2942C54.4182 77.64 53.2925 69.6003 55.473 68.254C57.5066 66.9965 60.6892 75.0245 62.8349 73.9641C64.9072 72.948 62.1984 65.0633 64.4 64.3556C66.9714 63.5248 72.3486 70.0405 73.1315 72.6244C76.1247 82.435 76.3166 102.63 76.3166 102.63"
      stroke="white"
      strokeWidth="2.5"
      strokeMiterlimit="10"
    />
    <path
      d="M82.2123 106.95L64.002 114.305M67.8405 120.461L85.4195 113.259M85.4195 113.259L87.2904 116.94L82.2997 121.647C82.9347 123.535 83.1916 127.984 78.8445 129.635C74.3658 131.336 71.3614 127.722 70.5984 126.585L64.2004 127.258L56.3247 110.193L79.2724 101.166L85.4195 113.259Z"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
    <path
      d="M154.256 67.2383C154.256 67.2383 187.127 66.5222 204.875 62.1656C204.875 62.1656 206.224 104.043 215.298 121.242C215.298 121.242 183.268 125.738 162.189 137.032C162.189 137.032 149.891 106.72 154.256 67.2383Z"
      fill="white"
      stroke="#160B28"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinejoin="bevel"
    />
    <path
      d="M76.4778 42.2673L89.6988 23.9012"
      stroke="#572E9E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M73.9004 26.4692L92.2742 39.6929"
      stroke="#572E9E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M71.9217 34.8998L94.2578 31.264"
      stroke="#572E9E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M84.9109 44.2481L81.2637 21.9133"
      stroke="#572E9E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

const DroppedBooks = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="201" height="154" viewBox="0 0 201 154" fill="none">
    <g clipPath="url(#clip0_208_8646)">
      <g opacity="0.7">
        <path d="M83.409 136.78L73.5709 93.2473L75.6345 48.7747L96.2342 104.043L83.409 136.78Z" fill="#8A61D1" />
        <path
          d="M122.756 77.206L126.929 75.666L142.9 128.371C142.9 128.371 105.765 148.932 94.7245 146.492C83.6842 144.051 83.4085 136.771 83.4085 136.771M83.4085 136.771L96.2389 104.039L75.634 48.7653L73.5704 93.2379L83.4085 136.771Z"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.3897 142.246L132.737 125.151L121.22 71.9247L114.257 77.9631"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.6318 138.123L123.999 120.967L112.503 71.532L107.651 74.9127"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.6651 133.218L109.343 114.469L106.166 66.0219L90.5356 88.7568"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.5187 122.799C92.1189 122.336 92.7191 121.874 93.3245 121.415C93.9294 120.948 96.5795 116.244 97.0763 116.828C97.357 116.047 98.3286 113.394 98.7969 113.087C99.8063 112.435 101.063 113.385 101.879 114.267C102.623 112.786 103.368 111.304 104.113 109.823"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.0988 105.474C99.3037 105.793 99.5133 106.107 99.7182 106.425C100.225 100.89 102.977 100.915 103.6 101.328C104.182 100.403 104.77 99.4826 105.353 98.5574"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.1922 97.3506C97.967 94.1391 97.6745 91.8217 97.1319 88.5649C97.9908 89.5244 100.43 90.1975 101.289 91.157C101.489 90.36 101.449 87.3975 101.688 86.0556C102.113 86.6975 103.869 87.8822 104.289 88.5194"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <ellipse cx="97.8711" cy="147.501" rx="28" ry="2.5" fill="#8A61D1" />
        <path
          d="M73.873 139C69.4726 130.6 61.373 122.167 57.873 119M62.873 136L50.873 128.5M137.873 142.5C140.206 140.167 145.873 134.8 149.873 132M148.373 141C152.373 136 160.873 133 168.873 133.5"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
        <path
          d="M91.3418 3C85.8418 21.5 107.712 40.1749 113.842 26C121.842 7.5 81.8418 12.5 91.3418 30.5C100.842 48.5 96.8418 55.5 95.3418 64"
          stroke="#8A61D1"
          strokeWidth="2.5"
          strokeLinecap="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_208_8646">
        <rect width="200" height="153.846" fill="white" transform="translate(0.5 0.0715332)" />
      </clipPath>
    </defs>
  </svg>
);

export {
  ThreeBooks,
  ExclamationErrorMarks,
  DropdownArrow,
  UploadDesignBehind,
  UploadDesignFront,
  GoogleDriveIcon,
  OneDriveIcon,
  UploadDesignTablet,
  UploadDesignMobile,
  HeroGradient,
  HeroErrorGradient,
  LoadingGroup,
  DroppedBooks,
};
