"use client";
import React from "react";
import { PlusJakartaSans } from "@/service/fonts";
import { cn } from "@/lib/utils";
import {
  BlueIllustration,
  GreenIllustration,
  YellowIllustration,
  RedIllustration,
} from "@/components/icons/landing/features";
import { Carousel, CarouselContent, CarouselDotsDark, CarouselItem } from "@/components/ui/carousel";
import { useForcedMixpanelRedirectTrack } from "@/service/hooks";
import { PAGE_IDS, QUINO_APP_URL } from "@/constants/landing";

type Feature = {
  title: string;
  description: string;
  icon: React.JSX.Element;
};

const features: Feature[] = [
  {
    title: "Document summary",
    description: "Make documents and books shorter and easier to read to head start your learning experience.",
    icon: <BlueIllustration className="min-h-[170px] min-w-[170px]" />,
  },
  {
    title: "Chat with your files",
    description: "Ask questions or clarity definitions based on the content of your documents and papers.",
    icon: <GreenIllustration className="min-h-[170px] min-w-[170px]" />,
  },
  {
    title: "Knowledge check",
    description: "Generate learning cards from your documents and test your knowledge right away.",
    icon: <YellowIllustration className="min-h-[170px] min-w-[170px]" />,
  },
  {
    title: "Study with friends",
    description: "Share projects and learning materials with your friends and form study groups using Quino.",
    icon: <RedIllustration className="min-h-[170px] min-w-[170px]" />,
  },
];

const FeatureCard = ({ feature }: { feature: Feature }) => {
  return (
    <div className="flex h-[462px] max-h-[405px] w-[320px] max-w-[360px] flex-col items-center justify-between gap-4 rounded-3xl bg-white/5 p-6 md:min-w-[250px] lg:h-full lg:w-[476px] lg:min-w-[400px] lg:gap-6 lg:p-8 xl:min-w-[550px] xl:flex-row">
      {feature.icon}
      <div className="flex h-full flex-col items-start justify-center gap-3">
        <h3 className="text-[28px]/[35.28px]">{feature.title}</h3>
        <p className="text-[16px]/[22.4px] text-light-purple">{feature.description}</p>
      </div>
    </div>
  );
};

const FeatureCarousel = () => {
  return (
    <Carousel className="w-full max-w-[24rem] px-4">
      <CarouselContent>
        {features.map((feature) => (
          <CarouselItem key={feature.title} className="basis-11/12">
            <FeatureCard key={feature.title} feature={feature} />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselDotsDark />
    </Carousel>
  );
};

const Features = () => {
  const redirect = useForcedMixpanelRedirectTrack();
  return (
    <section
    id={PAGE_IDS.FEATURES}
      className={cn(
        "flex w-full items-center justify-center overflow-x-hidden bg-[#271443] text-white",
        PlusJakartaSans.className,
      )}
    >
      <div className="max-w-[1180px] px-4 sm:px-6">
        <div className="container flex flex-col items-center justify-center gap-16">
          <div className="hidden flex-wrap items-center justify-center gap-6 md:flex">
            {features.map((feature) => (
              <FeatureCard key={feature.title} feature={feature} />
            ))}
          </div>
          <div className="md:hidden">
            <FeatureCarousel />
          </div>
          <div>
            <button
              onClick={() => redirect(QUINO_APP_URL, "landing_feature_redirect_to_app_button_clicked")}
              className="mb-20 flex h-[61px] w-[297px] items-center justify-center rounded-2xl bg-white px-8 py-6 text-[18px]/[22.68px] font-semibold text-primary hover:hover:bg-primitive-purple-50"
            >
              Start using Quino for free
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
